import React, {useEffect, useState} from 'react'
import './PerfilHome.css'
import {
    actionGetUserData
} from '../../actions'
import { useMediaQuery } from 'react-responsive'
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import SelectorSearcher from '../../components/generalComponents/selectorSearcher/SelectorSearcher';
import Header from '../../components/generalComponents/header/Header';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const PerfilHome = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const navigate = useNavigate();

    //ORIGINAL DATA
    const [userData, setUserData] = useState<any>(null);

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [situacionIVAList, setSituacionIVAList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);

    const [actividadList, setActividadList] = useState<any>(null);
    const [ingresosBrutosList, setIngresosBrutosList] = useState<any>(null);

    //FUNCTIONS
    useEffect(() => {
        loadHardCodedLists()
    }, [])

    useEffect(() => {
        actionGetUserData(setUserData, setActividadList, setSexoList, setEstadoCivilList)
    },[])

    const loadHardCodedLists = () => {
        const tipoDocuListHard = [
            {
                codigo: 'LE',
                descripcion: 'LE'
            },
            {
                codigo: 'CI',
                descripcion: 'CI'
            },
            {
                codigo: 'DU',
                descripcion: 'DNI'
            },
            {
                codigo: 'PAS',
                descripcion: 'Pasaporte'
            },
            {
                codigo: 'DU',
                descripcion: 'CUIT'
            }
        ]
        setTipoDocuList(tipoDocuListHard)

        const ingresosBrutosListHard = [
            {
                codigo: 0,
                descripcion: 'No inscripto'
            },
            {
                codigo: 1,
                descripcion: 'Inscripto'
            },
            {
                codigo: 2,
                descripcion: 'Exento'
            },
        ]
        setIngresosBrutosList(ingresosBrutosListHard)

        const situacionIVAListHard = [
            {
                codigo: 0,
                descripcion: 'No Incripto'
            },
            {
                codigo: 1,
                descripcion: 'Resp.Inscripto'
            },
            {
                codigo: 3,
                descripcion: 'Exento'
            },
            {
                codigo: 4,
                descripcion: 'No responsable'
            },
            {
                codigo: 5,
                descripcion: 'Consumidor Final'
            },
            {
                codigo: 6,
                descripcion: 'Cons.Final Pers.No Física'
            },
            {
                codigo: 7,
                descripcion: 'Adherido al Monotributo'
            },
            {
                codigo: 8,
                descripcion: 'Resp.no categorizado'
            },
            {
                codigo: 9,
                descripcion: 'IVA no alcanzado'
            },
            {
                codigo: 10,
                descripcion: 'Responsable Monotributo'
            },
            {
                codigo: 11,
                descripcion: 'Monotributo Social'
            },
            {
                codigo: 12,
                descripcion: 'Pequeño Contrib. Eventual'
            },
            {
                codigo: 13,
                descripcion: 'Pequeño Contrib.Eventual Socia'
            },
            {
                codigo: 14,
                descripcion: "RI Factura 'M'"
            },
            {
                codigo: 15,
                descripcion: "RI FACTURA 'A' C/CBU Informado"
            },
        ]
        setSituacionIVAList(situacionIVAListHard)
    }

    //MAIN RENDER
    return (
        <div className="perfilHome-container">
            <Header home colored special />
            <div className="perfilHome-container-main">
                <h4 className="perfilHome-container-main-superTitulo bold">Mi Perfil</h4>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos personales</h6>
                <div className="perfilHome-container-main-list">
                    {userData?.cuil ? 
                        <div className="perfilHome-container-main-list-doubleItem">
                            <CustomTextInput
                                label='Razon Social'
                                required
                                value={userData?.lastName + userData?.name}
                                disabled
                            />  
                        </div>
                        :
                        <>
                            <CustomTextInput
                                label='Apellido'
                                required
                                value={userData?.lastName}
                                disabled
                            />
                            <CustomTextInput
                                label='Nombre'
                                required
                                value={userData?.name}
                                disabled
                            />
                            <div className="perfilHome-container-main-list-invis"></div>
                            <div className="perfilHome-container-main-list-doubleItem">
                                <CustomTextInput
                                    label='Nombre completo'
                                    required
                                    value={userData?.lastName + ', ' + userData?.name}
                                    disabled
                                />  
                            </div>
                        </>
                    }
                    <div className="perfilHome-container-main-list-invis"></div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <p className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            selectedItem={userData?.cuil ? 'CUIT' : tipoDocuList?.filter((a: any) => a.codigo == userData?.documentId)[0]?.descripcion}
                            placeholder='Seleccioná tu tipo de documento'
                            disabled
                        /> 
                    </div>
                    {userData?.cuil ? 
                        <div className="perfilHome-container-main-list-specialInput">
                            <div className="perfilHome-container-main-list-specialInput-label">
                                <p className="perfilHome-container-main-list-specialInput-label-text bold">Número</p>
                                <p className="perfilHome-container-main-list-specialInput-label-requiered">&nbsp;*</p>
                            </div>
                            <div className="perfilHome-container-main-list-specialInput-main">
                                <input
                                    className="perfilHome-container-main-list-specialInput-main-input"
                                    style={{ width: '15%' }}
                                    maxLength={2}
                                    value={userData?.cuil?.slice(0, 2)}
                                    disabled
                                />
                                <b className="perfilHome-container-main-list-specialInput-main-line light large">-</b>
                                <input
                                    className="perfilHome-container-main-list-specialInput-main-input"
                                    style={{ width: '70%' }}
                                    maxLength={8}
                                    value={userData?.cuil?.slice(2, -1)}
                                    disabled
                                />
                                <b className="perfilHome-container-main-list-specialInput-main-line light large">-</b>
                                <input
                                    className="perfilHome-container-main-list-specialInput-main-input"
                                    style={{ width: '15%' }}
                                    maxLength={1}
                                    value={userData?.cuil?.slice(-1)}
                                    disabled
                                />
                            </div>
                        </div>
                        :
                        <CustomTextInput
                            label='Nro de documento'
                            required
                            value={userData?.documentNumber}
                            placeholder='Ingresá sólo números sin puntos'
                            maxLength={8}
                            disabled
                        />
                    }
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <p className="perfilHome-container-main-list-item-selectorWrapper-title bold">Sexo&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Sexo'
                            list={sexoList}
                            selectedItem={sexoList?.filter((a: any) => a.codigo == userData?.sexId)[0]?.descripcion}
                            placeholder='Seleccioná tu sexo'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        value={moment(userData?.birthDate).format('DD/MM/YYYY')}
                        disabled
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                    />
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de teléfono&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Tipo de teléfono' 
                            list={estadoCivilList}
                            selectedItem='PARTICULAR'
                            placeholder='Seleccioná tu Tipo de teléfono' 
                            disabled
                        />
                    </div>
                    <CustomTextInput
                        label='Celular'
                        required
                        maxLength={10}
                        disabled
                        value={userData?.phone}
                    />
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Situación ante el IVA&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Situación ante el IVA' 
                            list={situacionIVAList}
                            selectedItem={situacionIVAList?.filter((a: any) => a.codigo == userData?.iva)[0]?.descripcion} 
                            placeholder='Seleccioná tu Situación ante el IVA' 
                            disabled
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Estado Civil' 
                            list={estadoCivilList}
                            selectedItem={estadoCivilList?.filter((a: any) => a.codigo == userData?.civilStatus)[0]?.descripcion} 
                            placeholder='Seleccioná tu estado civil' 
                            disabled
                        />
                    </div>
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Domicilio</h6>
                <div className="perfilHome-container-main-list">
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de domicilio&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de domicilio'
                            selectedItem='PARTICULAR'
                            placeholder='Seleccioná tu tipo de domicilio'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Calle'
                        required
                        value={userData?.addressStreet}
                        disabled
                    />
                    <div className="perfilHome-container-main-list-list">
                        <CustomTextInput
                            label='Nro'
                            required
                            disabled
                            value={userData?.addressNumber}
                        />
                        <CustomTextInput
                            label='Piso'
                            disabled
                            value={userData?.piso}
                        />
                        <CustomTextInput
                            label='Dpto.'
                            disabled
                            value={userData?.dpto}
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Provincia&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Provincia'
                            disabled
                            selectedItem={userData?.provincia}
                            placeholder='Seleccioná tu provincia'
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Localidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Localidad'
                            selectedItem={userData?.localidad}
                            placeholder='Seleccioná tu localidad'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Código postal'
                        disabled
                        value={userData?.addressPostalCode}
                    />
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos de cuenta</h6>
                <div className="perfilHome-container-main-list">
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Actividad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Actividad' 
                            list={actividadList}
                            selectedItem={actividadList?.filter((a: any) => a.codigo == userData?.activity)[0]?.descripcion} 
                            placeholder='Seleccioná tu Actividad' 
                            disabled
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper">
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Ingresos brutos&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Ingresos brutos' 
                            list={ingresosBrutosList}
                            selectedItem={ingresosBrutosList?.filter((a: any) => a.codigo == userData?.rawIncome)[0]?.descripcion} 
                            placeholder='Seleccioná tu Ingresos brutos' 
                            disabled
                        />
                    </div>
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos de cuenta</h6>
                <div className="perfilHome-container-main-list">
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={userData?.username}
                        placeholder='nombre@ejemplo.com'
                        disabled
                    />
                    <CustomTextInput
                        label='Contraseña'
                        required
                        value='**********'
                        disabled
                        changePasswordClick={() => navigate('/change-password')}
                    />
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
            </div>
        </div>
    );
}

export default PerfilHome;