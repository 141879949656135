import {useState } from 'react'
import './ChangePassword.css'
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useNavigate } from 'react-router-dom'
import { LOADING } from '../../actions/types';
import axios from '../../actions/instanceActions';
import { netChangePassword } from '../../net/Connector';
import { genericErrorHandler } from '../../util/GenericErrorHandler';
import { GetCountryUrl } from '../../util/GetCountryUrl';

const ChangePassword = (props: any) => {
    const [contraseña, setContraseña] = useState<string>('')
    const [newContraseña, setNewContraseña] = useState<string>('')
    const [repeatNewContraseña, setRepeatNewContraseña] = useState<string>('')
    const dispatch = useDispatch();

    const navigate = useNavigate()

    const changePassword = async () => {
          dispatch({type: LOADING, payload: true});
          try {
            await axios({
              url: netChangePassword(),
              method: 'put', 
              headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
              },
              data: { currentPassword: contraseña, newPassword: newContraseña}
            })
            navigate(GetCountryUrl(props.countryId) + 'change-password-success')
          } catch (error) {
            genericErrorHandler(error)
          } finally {
            dispatch({ type: LOADING, payload: false });
          }
  };

    const validatePassword = (e: string) => {
        if (e.length >= 8) {
          if (/\d/.test(e) && /[a-zA-Z]/.test(e)) {
            if (/[A-Z].*\d|\d.*[A-Z]/.test(e)) {
              return true
            }
          }
        }
        return false
    }

    const checkNewPasswordLength = (inputString: string) => {
      return inputString?.length >= 8;
    }

    const checkNewPasswordAlphaNum = (inputString: string) => {
      const hasNumber = /\d/;
      const hasLetter = /[a-zA-Z]/;
      return hasNumber.test(inputString) && hasLetter.test(inputString);
    }

    const checkNewPasswordUpperCase = (inputString: string) => {
      const hasUpperCase = /[A-Z]/;
      return hasUpperCase.test(inputString);
    }

    const isDisabled = () => {
      return (contraseña === '' || !validatePassword(newContraseña) || !validatePassword(repeatNewContraseña))
    }
    
    return (
      <div className="changePassword-container">
        <Header home/>
        <div className="changePassword-container-main">
          <h4 className="changePassword-container-main-title bold">Cambiar contraseña</h4>
          <div className="changePassword-container-main-inputs">
            {props.userData?.hasPassword && 
              <CustomTextInput
                label='Contraseña'
                placeholder='*********'
                required
                changeText={(e: string) => setContraseña(e)}
                value={contraseña}
                password='var(--primary500)'
                forgotPasswordClick={() => navigate('/recovery')}
              />
            }
            <CustomTextInput
                label='Contraseña nueva'
                placeholder='*********'
                required
                changeText={(e: string) => setNewContraseña(e)}
                value={newContraseña}
                password='var(--primary500)'
                success={validatePassword(newContraseña)}
                error={newContraseña !== '' ? !validatePassword(newContraseña) : false}
            />
            <CustomTextInput
                label='Repetir nueva contraseña'
                placeholder='*********'
                required
                changeText={(e: string) => setRepeatNewContraseña(e)}
                value={repeatNewContraseña}
                password='var(--primary500)'
                success={validatePassword(repeatNewContraseña)}
                error={repeatNewContraseña !== '' ? repeatNewContraseña !== newContraseña || !validatePassword(repeatNewContraseña) : false}
            />
          </div>
          <div className="changePassword-container-main-notice">
            <b className="changePassword-container-main-notice-text regular medium">Tu contraseña nueva debe incluir: </b>
            <b className="changePassword-container-main-notice-text regular medium">&nbsp;&nbsp;&nbsp;&nbsp;{checkNewPasswordLength(newContraseña) ? <span  className="material-symbols-outlined" style={{ color: 'green', fontSize: 10 }}>done</span> : <ul>&bull;</ul>} Mínimo 8 caracteres</b>
            <b className="changePassword-container-main-notice-text regular medium">&nbsp;&nbsp;&nbsp;&nbsp;{checkNewPasswordAlphaNum(newContraseña) ? <span  className="material-symbols-outlined" style={{ color: 'green', fontSize: 10 }}>done</span> : <ul>&bull;</ul>} Letras y números</b>
            <b className="changePassword-container-main-notice-text regular medium">&nbsp;&nbsp;&nbsp;&nbsp;{checkNewPasswordUpperCase(newContraseña) ? <span  className="material-symbols-outlined" style={{ color: 'green', fontSize: 10 }}>done</span> : <ul>&bull;</ul>} 1 letra mayúscula</b>
          </div>
          <MainButton
            id='Continuar Boton' 
            disabled={isDisabled()}
            text='ACTUALIZAR CONTRASEÑA'
            onButtonPressed={() => changePassword()}
          />
        </div>
      </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userData: state.user.userData
  };
};


export default connect(mapStateToProps, null)(ChangePassword);