const hexToRgba = (hex: any) => {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const rgb = `${r}, ${g}, ${b}`;
    return `rgba(${rgb}, ${0.10})`;
};

export const estadoList = [
    {
        title: 'Vigente',
        text: 'Una cobertura activa durante un período de tiempo. Implica que el asegurado está protegido contra los riesgos y eventos especificados.',
        color: '#008744',
        colorOpacity: hexToRgba('#008744'),
        status: 'FINISHED'
    },
    {
        title: 'No vigente',
        text: 'El titular de la cobertura no ha pagado sus primas de seguro en el plazo acordado. El titular de la póliza ha incumplido con los pagos requeridos para mantener la cobertura vigente.',
        color: '#858585',
        colorOpacity: hexToRgba('#858585'),
        status: 'NOT_CURRENT'
    },
    {
        title: 'Anulada',
        text: 'Una cobertura existente que ha sido interrumpida por el solicitante antes de su fecha de vencimiento prevista o por falta de pago.',
        color: '#D12440',
        colorOpacity: hexToRgba('#D12440'),
        status: 'CANCEL_REQUESTED_ADMIN'
    },
    {
        title: 'Impaga',
        text: 'Una cobertura que está vigente, pero el asegurado no ha realizado el pago correspondiente de la prima. Esto puede llevar a la suspensión temporal de los beneficios de la póliza hasta que el pago sea efectuado, o eventualmente, a la cancelación de la cobertura si el pago no se realiza dentro del período de gracia estipulado por la compañía aseguradora.',
        color: '#E6540B',
        colorOpacity: hexToRgba('#E6540B'),
        status: 'OUTDATED_CREDIT'
    },
    {
        title: 'Renovada',
        text: 'Una cobertura existente que ha sido extendida o prolongada por otro período de tiempo después de su fecha de vencimiento original.',
        color: '#A705A7',
        colorOpacity: hexToRgba('#A705A7'),
        status: 'RENEWED'
    },
    {
        title: 'Emisión fallida',
        text: 'Una propuesta que ha sido anulada o interrumpida por la cía aseguradora antes de emitirse.',
        color: '#858585',
        colorOpacity: hexToRgba('#858585'),
        status: 'FAILED_EMISSION'
    }
]