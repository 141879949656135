import { useEffect, useState } from 'react';
import './HomeSeguro.css'
import { checkOutOfferHomeById } from '../../../actions'
import { estadoList } from '../../../util/EstadoListColors';

const HomeSeguro = (props: any) => {

    const [productPackage, setProductPackage] = useState<any>(null);
    const [activeSwitch, setActiveSwitch] = useState<boolean>(false);
    const [checkoutData, setCheckoutData] = useState<any>(null);
 
    useEffect(() => {
        checkOutOfferHomeById(props.data?.checkout?.id, setCheckoutData)
    }, [props.data])

    useEffect(() => {
        if(checkoutData) {
            setProductPackage(checkoutData?.offer?.packages?.filter((a) => a?.id === checkoutData?.offer?.currentPackage)[0])
            const list = [...props.checkoutDataList];
            list.push(checkoutData)
            props.setCheckoutDataList(list)
        }
    }, [checkoutData])

    useEffect(() => {
        if(props.data) setActiveSwitch(props.data?.enabled)
    }, [props.data])

    return (
        <div className="homeSeguro-container">
            <div className="homeSeguro-container-main">
                <div className="homeSeguro-container-main-imgWrap">
                    <img className="homeSeguro-container-main-imgWrap-img" src={props.data?.product?.image} />
                </div>
                <div className="homeSeguro-container-main-data">
                    <b 
                        className="homeSeguro-container-main-data-status regular large"
                        style={{ color: estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color }}
                    >{estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}</b>
                    <b className="homeSeguro-container-main-data-product large bold">{props.data?.product?.name + ' ' + props.data?.product?.model}</b>
                    <b className="homeSeguro-container-main-data-package large regular">{productPackage?.description}</b>
                    <div 
                        className="homeSeguro-container-main-data-switch"
                        onClick={() => {
                            if(props.data?.enabled) {
                                const reDeactivate = props.data?.enabled && estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.status === 'CANCEL_REQUESTED';
                                props.desactivar(props.data?.id, reDeactivate)
                            }
                            else props.activarPoliza(props.data?.id)
                        }}
                        style={{ backgroundColor: activeSwitch ? 'var(--primary500)' : 'var(--gray200)'}}
                    >
                        <div 
                            className="homeSeguro-container-main-data-switch-ball"
                            style={{ transform: activeSwitch ? 'translate(25px, 0px)' : 'translate(5px, 0px)' }}
                        ></div>
                    </div>
                    {props.goToDetails && <b onClick={() => props.goToDetails()} className="homeSeguro-container-main-data-ver bold large">VER COBERTURA</b>}
                    {props.reclamar && <b onClick={() => props.data?.enabled ? props.reclamar(productPackage) : {}} className="homeSeguro-container-main-data-ver bold large" style={{ opacity: props.data?.enabled ? 1 : 0.6, cursor: props.data?.enabled ? 'pointer' : 'auto' }}>DENUNCIAR</b>}
                </div>
            </div>
        </div>
    );
}

  
export default HomeSeguro;