import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import './CheckoutFinAdmin.css'
import success from '../../assets/lotties/success.json'
import pending from '../../assets/lotties/pending.json'
import error from '../../assets/lotties/errorPago.json'
import Lottie from 'react-lottie';
import logo from '../../assets/images/svgs/logoSecure.svg'
import successWaveDesktop from '../../assets/images/svgs/adminSuccessDesktop.svg'
import errorWaveDesktop from '../../assets/images/svgs/adminErrorDesktop.svg'
import successWaveMobile from '../../assets/images/svgs/adminSuccessMobile.svg'
import errorWaveMobile from '../../assets/images/svgs/adminErrorMobile.svg'
import pendingWaveDesktop from '../../assets/images/svgs/adminPendingDesktop.svg'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useMediaQuery } from 'react-responsive';
import { 
  actionDownloadPolicyMA, 
  actionDownloadPolicySC,
  certificadoAction,
  getPolicyInfo
} from '../../actions';

let isTabletOrMobile = false;
const CheckoutFinAdmin = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();
  const [estado, setEstado] = useState<any>(null)
  const [searchParams] = useSearchParams();
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
  const [policy, setPolicy] = useState<any>(null)
  const [currentPackage, setCurrentPackage] = useState<any>(null)
  const [downloading, setDownloading] = useState<boolean>(false);
  
  useEffect(() => {
    if (props.checkOutData) {
      setCurrentPackage(props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0])
    }
  }, [props.checkOutData])

  useEffect(() => {
    handleUrlParams()
  }, [])

  useEffect(() => {
    if(props.checkOutConfirmData) getPolicyInfo(props.checkOutConfirmData?.next?.config?.productInsuredUserId, setPolicy)
  }, [props.checkOutConfirmData])

  const handleUrlParams = () => {
    setEstado(searchParams.get("estado"))
  }

  const lottieSize = 150

  return (
    <div className="checkoutFinAdmin-container">
      <div className="checkoutFinAdmin-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: estado === 'Pendiente' ? pending : estado === 'Exito' ? success : error,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={lottieSize}
          width={lottieSize}
          isStopped={false}
          isPaused={false}
        />
        <img src={logo} className="checkoutFinAdmin-container-main-img"/>
        <h5 className="checkoutFinAdmin-container-main-title light">{estado === 'Pendiente' ? '¡La cobertura está en revisión!' : estado === 'Exito' ? '¡Felicidades! Tu cliente ya tiene su cobertura' : '¡Algo salió mal!'}</h5>
        {estado === 'Exito' && <h6 className="checkoutFinAdmin-container-main-poliza regular">El nro de póliza es {policy?.number}</h6>}
        {estado === 'Error' && <h5 className="checkoutFinAdmin-container-main-errorTitle regular">No pudimos completar la emisión de la cobertura</h5>}
        {estado === 'Exito' && <b className="checkoutFinAdmin-container-main-description regular large">Dentro de las próximas 24 h se la enviaremos por email<br/>junto al acceso de su portal de autogestión.</b>}
        {estado === 'Error' && <b className="checkoutFinAdmin-container-main-description regular large">El motivo es: {props.checkOutConfirmErrorMessage}<br/>Lamentamos este inconveniente, este error es poco habitual.<br/>Por favor, reiniciá el proceso y probá de nuevo.</b>}
        {estado === 'Pendiente' && <b className="checkoutFinAdmin-container-main-description regular large">Algunos de los datos cargados necesitan ser revisados, esto puede tardar un poco. Una vez finalizado el proceso, enviaremos un mail de confirmación junto al acceso de su portal de autogestión.</b>}
        {estado === 'Pendiente' ? 
          <div className="checkoutFinAdmin-container-main-bot">
            <MainButton
              id='VOLVER A COBERTURAS'
              fill
              color='var(--primary500)'
              text='VOLVER A COBERTURAS'
              onButtonPressed={() => {
                if(window.location.hostname == 'localhost') {
                  window.parent.postMessage('/insurance_policies', 'http://localhost:3000')
                } else {
                  window.parent.postMessage('/insurance_policies', appBaseIframe)
                }
              }}
            />
          </div>
        : estado === 'Exito' ? 
          <>
            <div className="checkoutFinAdmin-container-main-bot">
              <MainButton
                id='VOLVER AL INICIO'
                fill
                color='var(--primary500)'
                text='VOLVER AL INICIO'
                onButtonPressed={() => navigate('/cotizar?cId=12&admin=True')}
              />
            </div>
            <div className="checkoutFinAdmin-container-main-bot">
              <MainButton
                id='DESCARGAR PÓLIZA'
                text={downloading ? 'DESCARGANDO...' : 'DESCARGAR PÓLIZA'}
                border
                color='var(--primary500)'
                disabled={!policy?.number || policy?.number === 0}
                onButtonPressed={() => {
                  if(policy?.url) window.open(policy?.url)
                  else {
                    if(currentPackage?.insurer?.id === 17) {
                      actionDownloadPolicyMA(policy?.number, setDownloading)
                    } else if (currentPackage?.insurer?.id === 18) {
                      actionDownloadPolicySC(policy?.number, setDownloading)
                    } else {

                    }
                  }
                }}
              />
            </div>
          </>
          :
          <>
            {props.checkOutConfirmErrorMessage?.includes('Ya existe una solicitud/póliza con este dominio') ?
              <div className="checkoutFinAdmin-container-main-bot">
                <MainButton
                  id='DESCARGAR CERT. DE COBERTURA'
                  text='DESCARGAR CERT. DE COBERTURA'
                  fill
                  color='var(--primary500)'
                  onButtonPressed={() => props.certificadoAction(props.checkOutData?.id, props.checkOutData?.policyHolder?.id)}            
                />
              </div>
              :
              <div className="checkoutFinAdmin-container-main-bot">
                <MainButton
                  id='IR A COTIZACIONES'
                  fill
                  color='var(--primary500)'
                  text='IR A COTIZACIONES'
                  onButtonPressed={() => {
                    if(window.location.hostname == 'localhost') {
                      window.parent.postMessage('/cotizaciones', 'http://localhost:3000')
                    } else {
                      window.parent.postMessage('/cotizaciones', appBaseIframe)
                    }
                  }}
                />
              </div>
            }
            <div className="checkoutFinAdmin-container-main-bot">
              <MainButton
                id='IR A EMISIONES FALLIDAS'
                text='IR A EMISIONES FALLIDAS'
                border
                color='var(--primary500)'
                onButtonPressed={() => {
                  if(window.location.hostname == 'localhost') {
                    window.parent.postMessage('/insurance_policies, {Emisión fallida}', 'http://localhost:3000')
                  } else {
                    window.parent.postMessage('/insurance_policies, {Emisión fallida}', appBaseIframe)
                  }
                }}            
              />
            </div>
          </>
        }
      </div>
      <div className="checkoutFinAdmin-container-wave">
        {isTabletOrMobile ? 
          <img src={estado === 'Pendiente' ? pendingWaveDesktop : estado === 'Exito' ? successWaveMobile : errorWaveMobile} className="checkoutFinAdmin-container-wave-img"/>
          :
          <img src={estado === 'Pendiente' ? pendingWaveDesktop : estado === 'Exito' ? successWaveDesktop : errorWaveDesktop} className="checkoutFinAdmin-container-wave-img"/>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    checkOutConfirmErrorMessage: state.checkOut.checkOutConfirmErrorMessage,
    checkOutConfirmData: state.checkOut.checkOutConfirmData,
    checkOutData: state.checkOut.checkOutData,
  };
};

export default connect(mapStateToProps, {certificadoAction})(CheckoutFinAdmin);