import React, { useEffect, useState, useRef } from 'react'
import './Contact.css'
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import contactanos from '../../assets/images/contactanos.png';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { connect } from 'react-redux';
import {
  getGenericForm,
  postGenericForm
} from '../../actions'
import { useNavigate } from 'react-router-dom';
import {store} from '../../index';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = (props: any) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [nombre, setNombre] = useState<string>('');
  const [apellido, setApellido] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [mensaje, setMensaje] = useState<string>('');

  const [reCaptcha, setReCaptcha] = useState(null)
  const recaptchaRef = useRef();
  const [formList, setFormList] = useState<any>([]);

  const navigate = useNavigate();

  const countryId = store.getState().general.countryId
  var count = mensaje.length + mensaje.replace(/[^\n]/g, '').length;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    props.getGenericForm('contact', setFormList)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const validateEmail = (e: string) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e).toLowerCase())) {
        return false;
    } return true
  }

  const isDisabled = () => {
    return nombre === '' || !validateEmail(email) || celular.length !== 10 || mensaje === '' || !reCaptcha
  }

  const reCaptchaCapture = (value: any) => {
    setReCaptcha(value)
  }

  return (
    <div className="contact-container">
        <Header home colored fixed trans={scrollPosition > 30} special admin />      
        <div className="contact-container-main">
        <div className="contact-container-main-left">
          <h4 className="contact-container-main-left-title bold">Lo primero es escucharte</h4>
          <b className="contact-container-main-left-subTitle regular large">Por favor, ingresá tus datos y contanos en qué podemos ayudarte</b>
          <div className="contact-container-main-left-inputs">
            <div className="contact-container-main-left-inputs-list">
              <CustomTextInput
                label='Nombre'
                required
                changeText={(e: string) => setNombre(e.replace(/[^a-zA-Z\s]+/g, ''))}
                value={nombre}
                especial
              />
              <CustomTextInput
                label='Apellido'
                required
                changeText={(e: string) => setApellido(e.replace(/[^a-zA-Z\s]+/g, ''))}
                value={apellido}
                especial
              />
            </div>
            <div className="contact-container-main-left-inputs-list">
              <CustomTextInput
                label='E-mail'
                required
                changeText={(e: string) => setEmail(e)}
                value={email}
                success={validateEmail(email)}
                error={email.length > 0 ? !validateEmail(email) : false}
                errorMessage='E-mail inválido'
                especial
              />
              <CustomTextInput
                label='Celular'
                required
                changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                value={celular}
                especial
                maxLength={10}
                success={celular.length === 10}
                error={celular.length !== 10 && celular !== ''}
              />
            </div>
          </div>
          <div className="customTextInput-container-label">
            <p className="customTextInput-container-label-title bold">Escribí tu mensaje</p>
            <p className="customTextInput-container-label-requiered bold">&nbsp;*</p>
          </div>
          <textarea 
            className="contact-container-main-left-textarea"
            value={mensaje}
            onChange={(e: any) => {
              /* if(count < formList?.filter((a: any) => a.code === "contact_message")[0]?.config?.max || e.nativeEvent.inputType === "deleteContentBackward") {
                setMensaje(e.target.value)
              } */
              setMensaje(e.target.value)
            }}
            maxLength={255}
          />
          <div className="contact-container-main-left-maxLength">
            <p className="contact-container-main-left-maxLength-text regular small">Sólo se permiten 255 caracteres</p>
            <p className="contact-container-main-left-maxLength-text regular small">{mensaje.length}/255</p>
          </div>
          <div className="contact-container-main-left-sendWrapper">
            <div className="contact-container-main-left-sendWrapper-captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdFokQfAAAAAMQ3MMEbQmaltswGk50-MKQoaS8m"
                onChange={reCaptchaCapture}
              />
            </div>
            <div className="contact-container-main-left-sendWrapper-buttonWrapper">
              <MainButton
                id='Enviar Contacto'
                text='ENVIAR'
                onButtonPressed={() => {
                  const data = {
                    mail: email,
                    cellphone: celular,
                    name_lastname: apellido + ', ' + nombre,
                    contact_message: mensaje
                  }
                  props.postGenericForm('contact', data, reCaptcha, recaptchaRef, setReCaptcha, navigate, 'contacto-exito', countryId, null)
                }}
                disabled={isDisabled()}
              />
            </div>
          </div>
        </div>
        <div className="contact-container-main-right">
          <img className="contact-container-main-right-img" src={contactanos}/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default connect(null, {
  getGenericForm,
  postGenericForm
})(Contact);