import React, { useState } from 'react';
import './BurgerMenuItem.css';

const BurgerMenuItem = (props: any) => {

    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <div
            className="burgerMenuItem-container"
            onClick={() => props.action()}
            style={{ 
                display: props.disabled ? 'none' : 'flex',
                backgroundColor: props.isSelected || isHovered ? 'var(--gray50)'  : 'transparent' 
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.isSelected ? 'var(--secondary500)' : 'var(--primary500)' }}>{props.icon}</span>
            <p className="burgerMenuItem-container-text" style={{ color: props.isSelected ? 'var(--secondary500)' : 'var(--primary500)' }}>{props.label}</p>
        </div>
    );

}

export default BurgerMenuItem;