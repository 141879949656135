import React, {useEffect, useState} from 'react'
import './FormFieldBonificacionesCarfacil.css'
import MainButton from '../../../MainButton/MainButton'
import { useMediaQuery } from 'react-responsive';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';

let isTabletOrMobile = false;
const FormFieldBonificacionesCarfacil = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const [localAnswer, setLocalAnswer] = useState<any>({})

  const handleItemSelected = (list: any, item: any) => {
    let aux = {...localAnswer}
    if (item === null) delete aux[list];
    else aux[list] = item;
    setLocalAnswer(aux)
  }

  useEffect(() => {
    if (props.data?.input?.config?.defaults) {
      const updatedAnswer = {
        mercantil: props.data.input.config.defaults.mercantil,
        sancristobal: props.data.input.config.defaults.sancristobal,
      };
      setLocalAnswer(updatedAnswer);
    }
  }, [props.data?.input?.config?.defaults]);

  const isDisabled = () => {
    return !(localAnswer.hasOwnProperty('mercantil') && localAnswer.hasOwnProperty('sancristobal'));
  }

  return (
    <div className="formFieldBonificacionesCarfacil-container">
      <div className="formFieldBonificacionesCarfacil-container-main">
        <div className="formFieldBonificacionesCarfacil-container-main-selectorWrapper">
          <p className="formFieldBonificacionesCarfacil-container-main-selectorWrapper-title bold">Mercantil Andina&nbsp;<ul>*</ul></p>
          <SelectorSearcher
            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
            id='Mercantil Andina'
            list={props.data?.input?.config?.mercantil}
            onValueSelected={(a: any) => handleItemSelected('mercantil', a?.value)}
            selectedItem={localAnswer?.mercantil ? props.data?.input?.config?.mercantil?.filter((a: any) => a?.value === localAnswer?.mercantil)[0]?.label : ''}
            placeholder='Seleccionar...'
            deleteSelectedItem={() => handleItemSelected('mercantil', null)}
            noSearch
          /> 
        </div>
        <div className="formFieldBonificacionesCarfacil-container-main-selectorWrapper">
          <p className="formFieldBonificacionesCarfacil-container-main-selectorWrapper-title bold">San Cristóbal&nbsp;<ul>*</ul></p>
          <SelectorSearcher
            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
            id='San Cristóbal'
            list={props.data?.input?.config?.sancristobal}
            onValueSelected={(a: any) => handleItemSelected('sancristobal', a?.value)}
            selectedItem={localAnswer?.sancristobal ? props.data?.input?.config?.sancristobal?.filter((a: any) => a?.value === localAnswer?.sancristobal)[0]?.label : ''}
            placeholder='Seleccionar...'
            deleteSelectedItem={() => handleItemSelected('sancristobal', null)}
            noSearch
          /> 
        </div>
      </div>
      <div className="formFieldBonificacionesCarfacil-container-button">
        <MainButton
          id={props.data?.input?.config?.button}
          fill
          color='var(--primary500)'
          text={props.data?.input?.config?.button}
          disabled={isDisabled()}
          onButtonPressed={() => {
            const data = {
              "questionId": props.data?.id,
              "value": localAnswer,
            }
            props.next(data, true)
          }}
        />
      </div>
    </div>
  );
}

export default FormFieldBonificacionesCarfacil;