import React, {useState} from 'react'
import './FormFieldPhotoItem.css'
import { useMediaQuery } from 'react-responsive'

let isTabletOrMobile = false;
const FormFieldPhotoItem = (props) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const [image, setImage] = useState('')
    const [ghostImage, setGhostImage] = useState(null)
    const [loading, setLoading] = useState(false)

    const putImageS3 = (fileData, data, presignUrl, index) => {
        setLoading(true);        
        fetch(fileData)
            .then((response) => {
                return response.blob();
            })
            .then((content) => {
                return fetch(presignUrl, {
                    method: 'PUT',
                    body: content,
                    headers: {
                        'Content-Type': data.type
                    }
                })
            })
            .then((response) => {
                setGhostImage('')
                setImage(data.name);
                props.onUpdate(presignUrl, index);
                setLoading(false)
            })
            .catch((error) => {
                putImageS3(fileData, data, presignUrl, index);
            });
    }

    const inputAccept = (type) => {
        switch (type) {
            case "photo":
                return ".png, .jpg, .jpeg";
            case "file":
                return ".png, .jpg, .jpeg, .pdf";
            default:
                return ".png, .jpg, .jpeg";
        }
    }

    return (
        <div className="FormFieldPhotoItem-container">
            {loading && <div className="FormFieldPhotoItem-container-overLay"/>}
            <label 
                className="FormFieldPhotoItem-container-main"
                style={{ background: image !== '' ? 'var(--primary200)' : 'var(--gray50)' }}
            >
                {loading ? 
                    <div className="FormFieldPhotoItem-container-main-noImage">
                        <span className='FormFieldPhotoItem-container-loader'></span>
                    </div>
                    :
                    <React.Fragment>
                        {image === '' ? 
                            <div className="FormFieldPhotoItem-container-main-noImage">
                                <span className="material-symbols-outlined" style={{ fontSize: 18, color: 'var(--primary500)' }}>attach_file</span>
                                <b className="FormFieldPhotoItem-container-main-text bold medium">{props.data?.input?.type === 'photo' ? 'ADJUNTAR IMAGEN' : 'ADJUNTAR'}</b>
                            </div>
                            :
                            <div className="FormFieldPhotoItem-container-main-yesImage">
                                <b className="FormFieldPhotoItem-container-main-text regular large">{image}</b>
                                <span 
                                    className="material-symbols-outlined" 
                                    style={{ fontSize: 18, color: 'var(--primary500)' }}
                                    onClick={() => {
                                        setImage('')
                                        setGhostImage(null)
                                        props.onUpdate('', props.index);
                                    }}
                                >close</span>
                            </div>
                        }
                    </React.Fragment>
                }
                <input 
                    disabled={loading}
                    className="FormFieldPhotoItem-container-main-input" 
                    type="file" accept={inputAccept(props.data?.input?.type)}
                    onChange={(event) => {
                        props.onUpdate('', props.index);
                        if (event.target.files && event.target.files[0]) {
                            putImageS3(URL.createObjectURL(event.target.files[0]), event.target.files[0], props.a?.presignedUrl, props.index)
                        }
                    }}
                    value={ghostImage}
                />
            </label>
        </div>
    )
}

export default FormFieldPhotoItem;