

import React from 'react'
import './DesactivarPolizaSuccessAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const DesactivarPolizaSuccessAlert = (props: any) => {
  return (
    <div className="desactivarPolizaSuccessAlert-container">
        <div 
            className="desactivarPolizaSuccessAlert-container-close"
            onClick={() => props.dismiss()}
        >
            <span className="material-symbols-outlined" style={{ color: 'var(--gray700)' }}>close</span>
        </div>
        <span className="material-symbols-outlined" style={{ fontSize: 80, color: 'var(--primary200)' }}>forward_to_inbox</span>
        <p className="desactivarPolizaSuccessAlert-container-text bold">La solicitud ha sido enviada.<br/>En breve nos comunicaremos para gestionar<br/>la baja de tu cobertura.</p>
        <div className="desactivarPolizaSuccessAlert-container-bot">
            <MainButton
                id='ACEPTAR'
                fill
                color='var(--primary500)'
                text='ACEPTAR'
                onButtonPressed={() => props.dismiss()}
            />
        </div>
    </div>
  );
}

export default DesactivarPolizaSuccessAlert;