import React, { useEffect, useState } from 'react'
import './CheckOutProduct.css'
import { connect, useDispatch } from 'react-redux';
import {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct,
  getQuoteQuestions
} from '../../../actions'
import MainButton from '../../generalComponents/MainButton/MainButton';
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import { LOADING } from '../../../actions/types';
import { getCheckOutRequerements, ProductRequirement } from '../../../services/CheckoutService';
import { genericErrorHandler } from '../../../util/GenericErrorHandler';
import GetGlobalForm from '../../generalComponents/GlobalForms/getGlobalForm';
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';

const CheckOutProduct = (props: any) => {

  //PRODUCT REQUIREMENTS
  const [productRequirements, setProductRequirements] = useState<ProductRequirement[]>();
  const [productRequirementsInfo, setProductRequirementsInfo] = useState<ProductRequirement[]>();
  const [productRequirementsPhoto, setProductRequirementsPhoto] = useState<ProductRequirement[]>();

  const [localAnswers, setLocalAnswers] = useState<any>({});
  const [currentPackage, setCurrentPackage] = useState<any>(null);
  const [cotizarQuestion, setCotizarQuestion] = useState<any>(null);
  const [is0Km, setIs0Km] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (props.checkOutData?.id && currentPackage && is0Km !== null) {
        try {
          dispatch({ type: LOADING, payload: true });
          const checkOutRequerements = await getCheckOutRequerements(props.checkOutData.id);
          handleProductRequirementsLoad(checkOutRequerements, currentPackage);
        } catch (error) {
          genericErrorHandler(error);
        } finally {
          dispatch({ type: LOADING, payload: false });
        }
      }
    }
    fetchData()
  }, [props.checkOutData?.id, currentPackage, is0Km])

  useEffect(() => {
    if(productRequirements) {
      setProductRequirementsInfo(productRequirements?.filter((a: any) => a.input?.type === "text"))
      setProductRequirementsPhoto(productRequirements?.filter((a: any) => a.input?.type === "photo" || a.input?.type === "file"))
    }
  }, [productRequirements])

  const handleProductRequirementsLoad = (checkOutRequerements: any, currentPackage: any) => {
    let requierments = checkOutRequerements.map((item: any) => {
      if (
        currentPackage?.insurer?.id === 18 && 
        item?.input?.type === "photo"
      ) return { ...item, required: true };

      if (
        currentPackage?.insurer?.id === 17 && 
        item?.id === "cert_norod" && 
        is0Km
      ) return { ...item, required: true };

      if (
        item?.id === "cert_norod"
      ) return null;

      if (
        currentPackage?.insurer?.id !== 18 &&
        ["frente", "trasera", "lateral_conductor", "lateral_acompañante"]?.includes(item?.id)
      ) return null;

      if (
        currentPackage?.insurer?.id === 40 &&
        ["frente", "trasera", "lateral_conductor", "lateral_acompañante"]?.includes(item?.id)
      ) return null;

      return item;
    });
    requierments = requierments.filter(Boolean);
    setProductRequirements(requierments);
  };

  const isDisabled = () => {
    let control = false
    productRequirements?.map((a: any) => {
      if (a.required) {
        if (localAnswers[a.id] || localAnswers[a.id] === 0) {
          if (Array.isArray(localAnswers[a.id])) {
            if (localAnswers[a.id]?.filter(b => b !== '')?.length < a?.input?.presignedUrl?.length) {
              control = true
            }
          }
        } else {
          control = true
        }
      }
    })
    return control
  }

  useEffect(() => {
    if(props.checkOutData) {
      setCurrentPackage(props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0])
      getQuoteQuestions(props.checkOutData?.offer?.product?.categoryId, props.checkOutData?.offer?.quoteId, setCotizarQuestion)
    }
  }, [props.checkOutData])

  useEffect(() => {
    if(cotizarQuestion?.previousQuestions) {
      setIs0Km(cotizarQuestion?.previousQuestions?.filter((a: any) => a.questionId === "47_cero_km")[0]?.label === "true")
    }
  }, [cotizarQuestion])

  const renderForm = () => {
    return (
      <div className="checkOutProduct-container">
        <div className="checkOutProduct-container-main">
          <div 
            className="checkOutProduct-container-main-volver"
            onClick={() => window.history.back()}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
            <b className="checkOutProduct-container-main-volver-text bold medium">Volver</b>
          </div>
          <h6 className="checkOutProduct-container-main-title bold">Información del vehículo</h6>
          <div className="checkOutProduct-container-main-list">
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Año'
                value={props.checkOutData?.offer?.product?.modelo}
                disabled
              />
            </div>
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Marca'
                value={props.checkOutData?.offer?.product?.marca}
                disabled
              />
            </div>
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Modelo'
                value={props.checkOutData?.offer?.product?.title}
                disabled
              />
            </div>
          </div>
          <div className="checkOutProduct-container-main-list">
            {productRequirementsInfo?.map((a: any, indexA: number) => (
              <div 
                key={indexA} 
                className="checkOutProduct-container-main-list-item" 
              >
                <GetGlobalForm
                  parent='CheckOut'
                  item={a}
                  currentAnswer={localAnswers} 
                  setLocalAnswers={setLocalAnswers} 
                  next={null} 
                  setCurrentAnswer={null}
                  checkOutData={props.checkOutData}
                  is0Km={is0Km}
                  isSpecial={is0Km === "true" && currentPackage?.insurer?.id !== 17}
                />
              </div>
            ))}
          </div>
          {productRequirementsPhoto?.length !== 0 && <h6 className="checkOutProduct-container-main-title bold">Imágenes del vehículo</h6>}
          <div className="checkOutProduct-container-main-list">
            {productRequirementsPhoto?.map((a: any, indexA: number) => (
              <div 
                key={indexA} 
                className="checkOutProduct-container-main-list-item" 
              >
                <GetGlobalForm
                  parent='CheckOut'
                  item={a}
                  currentAnswer={localAnswers} 
                  setLocalAnswers={setLocalAnswers} 
                  next={null} 
                  setCurrentAnswer={null}
                  checkOutData={props.checkOutData}
                />
              </div>
            ))}
          </div>
          <h6 className="checkOutProduct-container-main-title bold">Coberturas</h6>
          <div className="checkOutProduct-container-main-list">
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Cobertura'
                value={props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.description}
                disabled
              />
            </div>
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Suma asegurada'
                value={currentPackage?.coverageGroups[0]?.coverages?.filter((a: any) => a.title?.toUpperCase() == 'SUMA ASEGURADA')[0]?.formattedValue}
                disabled
              />
            </div>
            <div className="checkOutProduct-container-main-list-item">
              <CustomTextInput
                label='Prima'
                value={currentPackage?.coverageGroups[0]?.coverages?.filter((a: any) => a.title?.toUpperCase() == 'PRIMA')[0]?.formattedValue}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="checkOutProduct-container-endWrapper">
          <div className="checkOutProduct-container-endWrapper-main">
            <DiscountDetailsComponent />
            <div className="checkOutProduct-container-endWrapper-main-detailsWrapper-buttonWrapper">
              <MainButton
                id='Continuar Boton'
                fill
                color='var(--primary500)'
                disabled={isDisabled()}
                text='CONTINUAR'
                onButtonPressed={() => {
                  props.checkOutProduct(props.checkOutData.id, localAnswers)
                  props.setRefPayment(true)
                }}
                altStyle10
              />
            </div>
          </div>
        </div>
      </div>
    )
  };

  if (productRequirements === undefined) {
    return <p>Cargando...</p>
  }

  return renderForm();
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
  };
};

export default connect(mapStateToProps, {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct
})(CheckOutProduct);