import React  from 'react'
import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Error } from '../../assets/images/svgs/error.svg';
import { ReactComponent as Success } from '../../assets/images/svgs/success.svg';
import { ReactComponent as CandadoPay } from '../../assets/images/svgs/candadoPay.svg';
import { checkOutConfirm } from '../../actions/CheckOutActions';

let isTabletOrMobile = false;

/**
 * Este componente es un botón de pago básico, el cual solo confirma el checkout-
 */
const PaymentMethodBasic = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const navigate = useNavigate()

    const confirmCheckout = () => {
        props.checkOutConfirm(props.checkOutData.id, null, props.checkOutData.offer.product.title, props.userData.name, props.originalParams, navigate, props.countryId)
    }

    const handlePayState = () => {
        if(props.checkOutConfirmStart) return "PaymentMethodCreditCard-containerStart"
        if(props.checkOutConfirmPending) return "PaymentMethodCreditCard-containerPending"
        if(props.checkOutConfirmError) return "PaymentMethodCreditCard-containerError"
        if(props.checkOutConfirmSuccess) return "PaymentMethodCreditCard-containerSuccess"
        return "PaymentMethodCreditCard-container"
    }

    const handleInnerPayState = () => {
        if(props.checkOutConfirmError) return <Error/>
        if(props.checkOutConfirmSuccess) return <Success/>
        if(props.checkOutConfirmPending) return <Success/>
        return (
            <React.Fragment> 
                <CandadoPay/>
                <p className="PaymentMethodCreditCard-container-text">{props.checkOutConfirmStart ? 'PAGANDO...' : 'PAGAR'}</p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div 
                className={handlePayState()} 
                id="payment_buttom"
                style={{
                    opacity: props.disabled ? 0.4 : 1,
                    cursor: props.disabled ? 'inherit' : 'pointer'
                }}
                onClick={() => {
                    if(props.disabled || props.checkOutConfirmStart) {
                        return
                    } else {
                        confirmCheckout()
                    }
                }}
            >
                {handleInnerPayState()}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        checkOutConfirmSuccess: state.checkOut.checkOutConfirmSuccess,
        checkOutConfirmError: state.checkOut.checkOutConfirmError,
        checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
        checkOutConfirmPending: state.checkOut.checkOutConfirmPending,
        checkOutDataPaymentInfo: state.checkOut.checkOutDataPaymentInfo,
        userData: state.user.userData,
        countryId: state.general.countryId
    };
};

export default connect(mapStateToProps, {
    checkOutConfirm
})(PaymentMethodBasic);
