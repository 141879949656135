import React from 'react'
import './CheckoutDetailsProduct.css'

const CheckoutDetailsProduct = (props: any) => { 

  const getInsurerLogoWidth = (id: number) => {
    switch (id) {
      case 17:
        return '60%';
      case 18:
        return '60%';
      case 40:
        return '20%';
      default:
        return '60%';
    }

  }

  return (
    <div className="checkoutDetailsProduct-container">
      <div className="checkoutDetailsProduct-container-period">
        <p className="checkoutDetailsProduct-container-period-text">{props.plan}</p>
      </div>
      <div className="checkoutDetailsProduct-container-data">
        <div className="checkoutDetailsProduct-container-data-texts">
          <b className="checkoutDetailsProduct-container-data-texts-title regular large">{props.checkOutData?.offer?.product?.title}</b>
          <h6 className="checkoutDetailsProduct-container-data-texts-plan bold">{props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.description}</h6>
          <div className="checkoutDetailsProduct-container-data-texts-insurer">
            <b className="checkoutDetailsProduct-container-data-texts-insurer-text regular medium">Asegura</b>
            <img 
              src={props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.insurer?.image} 
              className="checkoutDetailsProduct-container-data-texts-insurer-image"
              style={{ width: getInsurerLogoWidth(props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.insurer?.id) }}
            />
          </div>
        </div>
        <img src={props.checkOutData?.offer?.product?.image} className="checkoutDetailsProduct-container-data-image"/>
      </div>
    </div>
  );
}

export default CheckoutDetailsProduct;