

import React from 'react'
import './SiniestroAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const SiniestroAlert = (props: any) => {
    const insurerLink = () => {
        if(props.insurer?.name === "Mercantil Andina") {
            return 'https://servicios.mercantilandina.com.ar/asegurados/'
        } else {
            return 'http://autogestion.sancristobal.com.ar/'
        }
    }

    const openWhatsApp = () => {
        const phoneNumber = '+5491144168235';
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappUrl, '_blank');
    };      

    return (
        <div className="siniestroAlert-container">
            <div className="siniestroAlert-container-close" onClick={() => props.close()}>
                <span className="material-symbols-outlined">close</span>
            </div>
            <div className="siniestroAlert-container-data">
                <img className="siniestroAlert-container-data-img" src={props.insurer?.img}/>
                <p className="siniestroAlert-container-data-title regular large">Denunciar un siniestro</p>
                <b className="siniestroAlert-container-data-text regular large">Para denunciar un siniestro debes ponerte<br/>en contacto con tu aseguradora. Podés hacerlo<br/>a través de su web o su teléfono:</b>
                <b onClick={() => window.open(insurerLink(), '_blank')} className="siniestroAlert-container-data-number regular large" style={{ cursor: 'pointer' }}>{insurerLink()}</b>
                <b onClick={() => openWhatsApp()} className="siniestroAlert-container-data-number regular large" style={{ cursor: 'pointer' }}>+54 9 11 4416-8235</b>
            </div>
            <div className="siniestroAlert-container-botton">
                <MainButton
                    id='ACEPTAR'
                    fill
                    color='var(--primary500)'
                    text='ACEPTAR'
                    onButtonPressed={() => props.close()}
                />
            </div>
        </div>
    );

}

export default SiniestroAlert;