import React, {useState, useEffect, useRef} from 'react'
import { useMediaQuery } from 'react-responsive'
import './Home.css'
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom'
import Header from '../../components/generalComponents/header/Header';
import {
  fetchDataForHome,
  categoriaSelectedChange,
  removeInsuredArticle,
  offerProductById,
  resetPolicy,
  fetchCategoriaList,
  setRefCategory
} from '../../actions'
import { FormattedMessage } from 'react-intl';
import Cookies from 'universal-cookie';
import HomeSeguro from '../../components/homeComponents/HomeSeguro/HomeSeguro';
import AccesosRapidos from '../../components/homeComponents/AccesosRapidos/AccesosRapidos';
import CategoriaButton from '../../components/cotizarComponents/CategoriaButton/CategoriaButton';
import Modal from 'react-modal';
import DesactivarPolizaAlert from '../../components/alertComponents/DesactivarPolizaAlert/DesactivarPolizaAlert';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import SiniestroAlert from '../../components/alertComponents/SiniestroAlert/SiniestroAlert';
import AtencionAlert from '../../components/alertComponents/AtencionAlert/AtencionAlert';
import DesactivarPolizaSuccessAlert from '../../components/alertComponents/DesactivarPolizaSuccessAlert/DesactivarPolizaSuccessAlert';
import GetMetaTags from '../../util/GetMetaTags';


let isTabletOrMobile = false;
const Home = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
  
  const navigate = useNavigate()

  const [originalParam, setOriginalParam] = useState<any>(null)
  const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
  const [secureList, setSecureList] = useState<any>(null);
  const [siniestroAlert, setSiniestroAlert] = useState<boolean>(false);
  const [categoriaList, setCategoriaList] = useState<any>(null);
  const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
  const [desactivarAlertSuccess, setDesactivarAlertSuccess] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [reDeactivate, setReDeactivate] = useState<boolean>(false);
  const [checkoutDataList, setCheckoutDataList] = useState<any>([]);
  const [appId] = useState<any>(process.env.REACT_APP_ID);
  const [pendienteList, setPendienteList] = useState<any>(null);
  const [vigenteList, setVigenteList] = useState<any>(null);
  const [noVigenteList, setNoVigenteList] = useState<any>(null);

  const refCategory = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    props.categoriaSelectedChange(-1)
    props.fetchCategoriaList()
  }, [])

  useEffect(() => {
    if(props.userProductsActive.length > 0) {
      setSecureList(props.userProductsActive)
    }
  }, [props.userProductsActive])

  useEffect(() => {
    handleUrlParams()
  }, [])

  const handleUrlParams = () => {
    let cookiesUA = new Cookies();
    cookiesUA = cookiesUA.get('userAccessToken')
    if(cookiesUA) {
      props.fetchDataForHome(setPendienteList, setVigenteList, setNoVigenteList)
    }
  }

  const siniestroModal = () => {
    return (
      <Modal
        isOpen={siniestroAlert}
        onRequestClose={() => setSiniestroAlert(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <SiniestroAlert
          close={() => setSiniestroAlert(false)}
        />
      </Modal>
    )
  }

  const desactivarModal = () => {
    return (
      <Modal
        isOpen={desactivarAlertId !== -1}
        onRequestClose={() => setDesactivarAlertId(-1)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <DesactivarPolizaAlert
          desactivarAlert={()=> setDesactivarAlertId(-1)}
          desactivarPressed={() => {
            props.removeInsuredArticle(desactivarAlertId, setRefresh, refresh, setDesactivarAlertSuccess)
            setDesactivarAlertId(-1)
          }}
          reDeactivate={reDeactivate}
        />
      </Modal>
    )
  }

  const desactivarSuccessModal = () => {
    return (
      <Modal
        isOpen={desactivarAlertSuccess}
        onRequestClose={() => setDesactivarAlertSuccess(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <DesactivarPolizaSuccessAlert
          dismiss={()=> setDesactivarAlertSuccess(false)}
        />
      </Modal>
    )
  }

  const atencionModal = () => {
    return (
      <Modal
        isOpen={atencionAlert}
        onRequestClose={() => setAtencionAlert(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <AtencionAlert
          close={() => setAtencionAlert(false)}
        />
      </Modal>
    )
  }

  const getProductRegionId = (categoryId: any) => {
    switch (categoryId) {
      case 18:
        return 9242;
      case 19:
        return 9243;
      case 20:
        return 9244;
      default:
        return 9242;
    }
  }

  useEffect(() => {
    setCategoriaList(props.categorias)
  }, [props.categorias, props.userData])

  useEffect(() => {
    if (props.categoryRef && refCategory?.current) {
      scrollToCategory()
      props.setRefCategory(false)
    }
  }, [props.categoryRef, refCategory?.current])

  const scrollToCategory = () => {
    refCategory?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="home-container">
      <GetMetaTags page={'home'} />
      <Header home/>
      <div className="home-container-main">
        <h4 className="home-container-main-title bold"><FormattedMessage id="page.admin.title" defaultMessage="Hola {user}" values={{user: props.userData?.name}}/></h4>
        {secureList && 
          <div className="home-container-main-subContainer">
            <h6 className="home-container-main-subContainer-title bold">Mis coberturas</h6>
            <div className="home-container-main-subContainer-list">
              {secureList.map((a: any) => (
                <HomeSeguro 
                  data={a}
                  goToDetails={() => navigate(`/policy/${a.id}` + `?checkoutId=${a.checkout?.id}`)}
                  desactivar={(id: number, reDeactivate: boolean) => {
                    setReDeactivate(reDeactivate)
                    setDesactivarAlertId(id)
                  }}
                  activarPoliza={(id: number) => appId == 6 ? {} : props.offerProductById(id, getProductRegionId(a.categoryId), originalParam, navigate, props.countryId)}
                  setCheckoutDataList={setCheckoutDataList}
                  checkoutDataList={checkoutDataList}
                />
              ))}
            </div>
          </div>
        }
        <div className="home-container-main-subContainer">
          <h6 className="home-container-main-subContainer-title bold"><FormattedMessage id="page.admin.subTitle.accRapidos" defaultMessage="Accesos rápidos"/></h6>
          <div className="home-container-main-subContainer-accesoslist">
            <div className="home-container-main-subContainer-accesoslist-item">
              <AccesosRapidos
                text='Denunciar un siniestro'
                icon='e911_emergency'
                onPressed={() => appId == 6 ? navigate('/reclamos') : setSiniestroAlert(true)}
                disable={checkoutDataList?.filter((a: any) => a.status === "FINISHED" || a.status === "RENEWED")?.length === 0}
              />
            </div>
            <div className="home-container-main-subContainer-accesoslist-item">
              <AccesosRapidos
                text='Atención al cliente'
                icon='contact_support'
                onPressed={() => setAtencionAlert(true)}
              />
            </div>
          </div>
        </div>
       {appId != 6 && categoriaList?.length !== 0 && <div className="home-container-main-subContainer" ref={refCategory}>
          <h6 className="home-container-main-subContainer-title bold"><FormattedMessage id="page.admin.subTitle.aseProducto" defaultMessage="Asegurar otro producto"/></h6>
          <div className='home-container-main-subContainer-productList'>
            {categoriaList?.map((a: any, index: Number) => (
                <CategoriaButton
                  key={index}
                  id={a.name + ' boton'}
                  commingSoon={a.coming_soon}
                  name={a.name} 
                  image={a.category.imageIcon}
                  selected={a.category.id === props.categoriaSelected}
                  categoryPressed={() => {
                    props.categoriaSelectedChange(a.category.id)
                    navigate('/oferta?productId=' + getProductRegionId(a.category.id))
                  }}
                  home={true}
                />
              ))
            }
          </div>
        </div>}
      </div>
      {desactivarModal()}
      {siniestroModal()}
      {atencionModal()}
      {desactivarSuccessModal()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userData: state.user.userData,
    userProductsActive: state.home.userProductsActive,
    categorias: state.cotizar.categorias,
    categoriaSelected: state.cotizar.categoriaSelected,
    categoryRef: state.general.categoryRef,
  };
};


export default connect(mapStateToProps, {
  fetchDataForHome,
  categoriaSelectedChange,
  removeInsuredArticle,
  offerProductById,
  resetPolicy,
  fetchCategoriaList,
  setRefCategory
})(Home);