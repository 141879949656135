import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {
  categoriaSelectedChange,
  getQuoteData,
  actionCreateOfferInOneStep,
  getQuoteQuestions
} from '../../actions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import './Cotizar.css'
import automovilLottie from '../../assets/lotties/automovil.json'
import Lottie from 'react-lottie';
import FormFieldQuoteCarfacil from '../../components/generalComponents/GlobalForms/FormFields/FormFieldQuoteCarfacil/FormFieldQuoteCarfacil';

const Cotizar = (props: any) => {

  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  const [quoteData, setQuoteData] = useState<any>(null);
  const [previousQuoteData, setPreviousQuoteDate] = useState<any>(null);
  
  //OTHER
  const [admin, setAdmin] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
  const navigate = useNavigate();

  useEffect(() => {
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('Not-Emitting', appBaseIframe)
    } else {
      window.parent.postMessage('Not-Emitting', 'http://localhost:3000')
    }
    handleUrlParams()
  }, [])

  const handleCotizar = (data: any) => {
    props.actionCreateOfferInOneStep(data, setLoadingOffer, navigate, 12, admin, appBaseIframe)
  }

  const handleUrlParams = () => {
    let categoryId: any = searchParams.get("cId")
    let admin: any = searchParams.get("admin")
    let quote: any = searchParams.get("quote")
    categoryId = parseInt(categoryId)
    if (admin) {
      setAdmin(true)
    }
    if (quote && categoryId) {
      getQuoteQuestions(categoryId, quote, setPreviousQuoteDate);
    }
    if (categoryId) {
      props.categoriaSelectedChange(categoryId)
      props.getQuoteData(categoryId, setQuoteData)
    }
  }

  return (
    <div className="cotizar-container">
      {loadingOffer ? 
        <div className="cotizar-container-mainLoading">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: automovilLottie
            }}
            height={400}
            width={400}
          />
          <h4 className="cotizar-container-mainLoading-text regular">Yendo a buscar tu cotización</h4>
        </div>
        :
        <div className="cotizar-container-main">
          <FormFieldQuoteCarfacil
            cotizar={(data: any) => handleCotizar(data)}
            quoteData={quoteData}
            previousQuoteData={previousQuoteData}
          />
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  categoriaSelectedChange,
  getQuoteData,
  actionCreateOfferInOneStep
})(Cotizar);