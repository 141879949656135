import React, {useState} from 'react'
import './FormFieldNumber.css'
import FormFieldNumberStyleOne from './FormFieldNumberStyleOne/FormFieldNumberStyleOne';
import FormFieldNumberStyleTwo from './FormFieldNumberStyleTwo/FormFieldNumberStyleTwo';

const FormFieldNumber = (props: any) => {

    const formStyle: any = {
        "alternative": { component: FormFieldNumberStyleTwo },
        undefined: { component: FormFieldNumberStyleOne }
    }
  
    const FormComponent: any = formStyle[props.data?.input?.config?.style]?.component;

    return (
        <FormComponent
            data={props.data}
            onUpdate={(value: any) => props.onUpdate(value)}
            parent={props.parent}
            next={props.next}
            onUpdateAlt={(value: any) => {
                const data = {
                    "questionId": props.data.id,
                    "value": parseInt(value),
                }
                props.onUpdateAlt(data)
            }}
        />
    );
}

export default FormFieldNumber;