import React, {useState} from 'react'
import './FormFieldNumberStyleTwo.css'
import SearchInputComponent from '../../../../../searchInputComponent/SearchInputComponent';
import imgCoti from '../../../../../../assets/images/cotizacionesCarfacil.png'

const FormFieldNumberStyleTwo = (props: any) => {

    const [input, setInput] = useState<string>('')

    return (
        <div className="FormFieldNumberStyleTwo-container-main">
            <img className="FormFieldNumberStyleTwo-container-main-img" src={imgCoti}/>
            <h4 className="FormFieldNumberStyleTwo-container-main-title regular">Ingresá el nro de cotización en la barra de búsqueda</h4>
            <SearchInputComponent
                setInput={setInput}
                input={input}
                handleSearchButton={() => props.onUpdateAlt(parseInt(input))}
            />
        </div>
    );
}

export default FormFieldNumberStyleTwo;