// lib
import React, { useEffect, useState } from "react";
import './PaymentCard.css'
import { getUserCreditCardInfo } from "../../../actions";

const PaymentCard = (props: any) => {

    const [paymentData, setPaymentData] = useState<any>(null);

    useEffect(() => {
        getUserCreditCardInfo(props.policy?.paymentInfoResponseDTO?.id, props.userData?.id, setPaymentData) 
    }, [])

    return (
        <div className="paymentCard-container">
            <div className="paymentCard-container-wrapper">
                <div className="paymentCard-container-wrapper-card">
                    <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--gray700)' }}>credit_card</span>
                    <p className="paymentCard-container-wrapper-text regular">{paymentData?.brand?.name?.toUpperCase()} **** **** **** {paymentData?.lastDigits}</p>
                </div>
            </div>
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--statusSuccessPrimary)' }}>done</span>
        </div>
    )
}

export default PaymentCard;