import { useState } from 'react';
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldDate.css';
import MainButton from '../../../MainButton/MainButton';
import Moment from 'moment/moment'

const FormFieldDate = (props: any) => {

  const [localText, setLocalText] = useState<string>('')

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && localText?.length >= 4) {
        confirm()
    }
  }

  const confirm = () => {
    const data = {
        "questionId": props.data?.id,
        "value": Moment(localText, 'YYYY-MM-DD').utcOffset('+0000').startOf('day').toISOString()
    }
    props.next(data, props.data?.input?.config?.buttom === 'COTIZAR')
}
  
  return (
    <div 
      className="formFieldDate-container"
      id={props.data.display.name}
    >
      <CustomTextInput
        maxLength={props.data?.input?.config?.max}
        label={props.data?.display?.discripcion}
        required={props.data?.required}
        placeholder={props.data?.display?.placeholder}
        value={localText}
        date
        changeText={(e: string) => setLocalText(e)}
        onKeyDown={(event: any) => handleKeyPress(event)}
      />
      {props.parent === 'Cotizar' && 
        <div className="formFieldDate-container-button">
          <MainButton
            id={props.data?.input?.config?.button === 'COTIZAR' ? 'COTIZAR' : 'CONTINUAR'}
            fill
            color='var(--primary500)'
            text={props.data?.input?.config?.button === 'COTIZAR' ? 'COTIZAR' : 'CONTINUAR'}
            disabled={localText?.length < 4}
            onButtonPressed={() => confirm()}
          />
        </div>
      }
    </div>
  )
}

export default FormFieldDate;