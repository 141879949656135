

import React from 'react'
import './NumeroCotizacionModal.css'

const NumeroCotizacionModal = (props: any) => {

    return (
        <div className="numeroCotizacionModal-container">
            <span 
                className="material-symbols-outlined" 
                style={{ color: 'var(--gray700)', cursor: 'pointer', marginLeft: 'auto' }}
                onClick={() => props.close()}
            >close</span>
            <div className="numeroCotizacionModal-container-main">
                <span className="material-symbols-outlined" style={{ color: 'var(--gray200)', fontSize: 60 }}>sentiment_dissatisfied</span>
                <h5 className="numeroCotizacionModal-container-main-title light">¡Lo sentimos mucho!</h5>
                <p className="numeroCotizacionModal-container-main-text regular">{props.text}</p>
            </div>
        </div>
    );
}

export default NumeroCotizacionModal;