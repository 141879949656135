import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';
import Facebook from './assets/images/svgs/facebook.svg';
import Instagram from './assets/images/svgs/instagram.svg';
import Linkedin from './assets/images/svgs/linkedin.svg';
import Whatsapp from './assets/images/svgs/whatsapp.svg';
import WhatsappImg from './assets/icons/whatapps.png';
import InstagramImg from './assets/images/instagram.png';
import LinkedinImg from './assets/images/linkedin.png';
import FacebookImg from './assets/images/facebookC.png';

export const countries = [
  {
    title: "ARGENTINA",
    id: 1,
    img: Argentina,
    url: '/',
    lang: () => import('./assets/lang/es-AR-Carfacil.json'),
    htmlMetaInfo: {
      title: 'WeCover - Argentina - Contratá tu seguro en segundos 100% Online.'
    },
  }
];

export const categorias = null;

export const burgerMenuList = (props: any, navigate: any, path: any, setAtencionAlert: any) => [
  {
      id: 1,
      label: 'Home',
      icon: 'home',
      action: () => {
          props.setBurgerOpen(false)
          if (props.userAccessToken) {
              navigate('/home')
          } else {
              navigate('/inicio')
          }
      },
      selected: path === '/home' || path.includes('/policy') || path.includes('/circulacion')
  },
  {
      id: 2,
      label: 'Mi perfil',
      icon: 'account_circle',
      action: () => {
          props.setBurgerOpen(false)
          navigate('/perfil')
      },
      selected: path === '/perfil' || path === '/change-password' || path === '/change-password-success' || path === '/recovery' || path === '/recovery-code' || path === '/recovery-code-form' || path === '/recovery-code-form-finish'
  },
  {
      id: 3,
      label: 'Mis coberturas',
      icon: 'verified_user',
      action: () => {
          props.setBurgerOpen(false)
          navigate('/mis_coberturas')
      },
      selected: path === '/mis_coberturas'
  },
  {
      id: 4,
      label: 'Mis siniestros',
      icon: 'e911_emergency',
      action: () => {
          props.setBurgerOpen(false)
          navigate('/reclamos')
      },
      selected: path === '/reclamos'
  },
  {
      id: 5,
      label: 'Atención al cliente',
      icon: 'contact_support',
      action: () => {
          props.setBurgerOpen(false)
          if (props.userAccessToken) {
              setAtencionAlert(true)
          } else {
              navigate('/contacto')
          }
      },
      selected: path === '/contacto'
  }
]

export const socialLinks = [
  {
    svg: Facebook,
    img: FacebookImg,
    link: (country: number) => 'https://www.facebook.com/wecoverok/'
  },
  {
    svg: Instagram,
    img: InstagramImg,
    link: (country: number) => 'https://www.instagram.com/wecoverok/',
  },
  {
    svg: Linkedin,
    img: LinkedinImg,
    link: (country: number) => 'https://www.linkedin.com/company/wecoverok'
  },
  {
    svg: Whatsapp,
    img: WhatsappImg,
    link: (country: number) => 'https://api.whatsapp.com/send/?phone=%2B5491169602118&text=Hola+WeCover+Arg&app_absent=0'
  }
]