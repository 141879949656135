import React from "react";
import './PaymentEfectivo.css'

const PaymentEfectivo = (props: any) => {
    return (
        <div className="paymentEfectivo-container">
            <div className="paymentEfectivo-container-wrapper">
                <div className="paymentEfectivo-container-wrapper-card">
                    <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--gray700)' }}>payments</span>
                    <p className="paymentEfectivo-container-wrapper-card-text regular">Efectivo</p>
                </div>
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--statusSuccessPrimary)' }}>done</span>
            </div>
        </div>
    )
}

export default PaymentEfectivo;

