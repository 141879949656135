//LOGIN
export const getBasic = () => 'Basic V2VDb3ZlcjpGYWN1SXNHYXlzaGFu';

export const loginUserAuth = () => '/oauth/token';

export const sendCodeValidate = (codigo: string) => '/public/user/confirmation_email?codigo=' + codigo;

export const getCodeValidate = ()  => '/public/user/confirmation_code';

export const createUser = () => '/public/user';

export const getIdRecoveryPassword = () => '/public/user/reset_password';

export const postSuccessCodeRecoveryEmail = (id: string) => `/public/user/reset_password/${id}/email`;

export const postSuccessNewPassword = (id: string) => `/public/user/reset_password/${id}/password`;

//USER
export const getUserMine = () => '/public/user/mine';

export const netDeviceUsed = (device: any, source: any) => '/v2/user/deviceUsed?device=' + device + '&source=' + source;

export const getUserDataById = (id: any) => '/v2/user/' + id

export const getCivilTypes = () => '/public/user/civil';

export const netCarfacilAvtividades = (insurerId: any) => '/v2/user/activities/' + insurerId //CARFACIL

export const getNacionalidades = (insurerId: any) => '/v2/user/nacionalidades/' + insurerId; //CARFACIL

export const getSexTypes = () => '/public/user/sexo';

export const getStatesCon = () => '/public/user/provincias_table_orbis';

export const getLocByIdCon = (locId: any) => '/public/user/localidad_id_table_orbis?locId=' + locId;

export const googleApiPlacesAddressProfile = (searchParam: any, pais: any) => 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + searchParam + '&key=AIzaSyDH19rVbhD4pIfNCp70KBxfWUjVJFONdGY&language=es&components=country:' + pais;

export const googleApiPlacesLatLng = (lat: any, lng: any) => 'https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + lng + '&key=AIzaSyDH19rVbhD4pIfNCp70KBxfWUjVJFONdGY';

export const editUser = () => '/public/user/edit';

export const editUserById = (id: any) => '/v2/user/' + id

export const getLocIdCon = (state: any, city: string) => '/public/user/existe_localidad_table_orbis?codPro=' + state + '&localidad=' + city;

export const getCPsByCityCon = (city: string) => '/public/user/codigo_ciudades_table_orbis?city=' + city;

export const getCitiesByStatesCon = (state: any, localidad: string) => '/public/user/ciudades_provincias_table_orbis?state=' + state + '&localidad=' + localidad;

export const getPlaces = () => '/place'

export const getUserPlacesById = (id: number) => '/place?parent=' + id

export const getPlaceById = (id: number) => '/place/' + id

export const netChangePassword = () => '/v2/user/me/password'

export const createEditUser = (insurerId: any) => '/v2/user/fast_user/' + insurerId

export const netGetUserListForAdmin = () => '/v2/user'

export const getPardoData = (type: string) => '/v2/user/pardo_data/' + type //PARDO

export const getPardoLocalidades = (provinciaId: string) => '/v2/user/pardo_data_localidades/' + provinciaId //PARDO

export const getPardoCodigosPostales = (localidadId: string) => '/v2/user/pardo_data_codigos_postales/' + localidadId //PARDO

export const netCheckUserExistance = (numeroDocumento: any) => '/v2/user/document/' + numeroDocumento

export const netGetLocalidades = (codigoPostal: string) => '/v2/user/localidades/' + codigoPostal

//QUOTE
export const getAllCategories = () => '/v2/product_category/';

export const netQuoteCotizaCategoria = (categoriaId: Number) => '/v2/' + categoriaId + '/quote'

export const netAnswerCotizar = (categoriaId: Number, quoteId: String) => '/v2/' + categoriaId + '/quote/' + quoteId + '/answers'

export const netAnswerOpenCotizar = (categoryId: number, numeroCotizacion: number) => '/' + categoryId + '/offers/mercantil/' + numeroCotizacion

export const netGetQuoteQuestions = (categoryId: Number, quote: String) => '/v2/' + categoryId + '/quote/' + quote

export const netDeleteQuoteQuestions = (categoryId: Number, quote: String, stepId: any) => '/v2/' + categoryId + '/quote/' + quote + '/' + stepId

export const netGetVersionList = (año: any, marcaModelo: any) => '/v2/12/quote/info_auto/' + marcaModelo + '/' + año;

export const netQuoteData = (categoryId: Number) => '/v2/' + categoryId + '/quote/questions';

export const netGetLocalidadList = (categoryId: any, codigoPostal: any) => '/v2/' + categoryId + '/quote/postal/' + codigoPostal

//OFFER
export const netGetOffer = (categoryId: Number, offer: String) => '/' + categoryId + '/offers/' + offer

export const netModifyOffer = (categoriaId: Number, offerId: Number, coverageId: Number) => '/' + categoriaId + '/offers/' + offerId + '/coverages/' + coverageId

export const netModifyOfferByPackage = (categoriaId: Number, offerId: Number, packageId: Number, coverageId: Number) => '/' + categoriaId + '/offers/' + offerId + '/package/' + packageId + '/coverages/' + coverageId

export const netSelectPackage = (categoriaId: number, id: string) => '/' + categoriaId + '/offers/' + id + '/currentPackage'

export const netModifyOfferDate = (categoriaId: number, id: number) => '/' + categoriaId + '/offers/' + id + '/validity'

export const netPostOffer = () => '/offers'

export const netCreateOffer = (categoriaId: number, quoteId: string) => '/' + categoriaId + '/offers/' + quoteId;

export const netGetRestrictions = (categoriaId: number, offerId: number, packageId: number) => '/' + categoriaId + '/offers/' + offerId + '/package/' + packageId + '/restriction'

export const netGetContactMethods = () => '/contactmethods'

export const netGetContactMethodsWhatsapp = () => '/contactmethods/whatsapp'

export const netInsurerList = () => '/v2/insurer'

export const netCreateOfferInOneStep = () => '/create_offer_one_step'

//CHECKOUT
export const netCheckOutOffer = () => '/checkout_process'

export const netCheckOutOfferById = (id: any) => '/checkout_process/' + id

export const netCheckOutOfferHomeById = (id: any) => '/checkout_data/' + id

export const netCheckOutPolicyHolder = (id: string, userId: number) => '/checkout_process/' + id + '/policyHolder?id=' + userId

export const netGetPatData = (checkoutId: any) => '/ss/pat_config?checkoutId=' + checkoutId

export const netAddPatChile = (userId: any) => '/user/' + userId + '/pat'

export const netCheckOutRequerements = (id: string) => '/checkout_process/' + id + '/customer_product_requeriments'

export const netCheckOutPaymentOptions = (id: string) => '/checkout_process/' + id + '/payment_options'

export const getCreditCardUserById = (userId: any) => '/v2/user/' + userId + '/credit_cards'

export const netSaveCbuCheckout = (userId: number) => '/v2/user/' + userId + '/bank_account'

export const netGetPatChile = (userId: number, patId: number) => '/user/' + userId + '/pat/' + patId

export const netCheckOutProductInfo = (id: string) => '/checkout_process/' + id + '/productInfo'

export const netCheckOutPaymentFinish = (id: string) => '/checkout_process/' + id + '/paymentInfo'

export const getBanks = (brandId: string) => '/public/user/creditcard/banks/' + brandId;

export const getEmisoras = () => '/credit_card_brand'

export const getBrands = () => '/public/user/creditcard/brands';

export const postCreditCard = (userId: any) => '/user/' + userId + '/credit_cards';

export const netGetUserCreditCardInfo = (creditCardId: number, userId: any) => '/user/' + userId + '/credit_cards/' + creditCardId

export const netUserCbuInfo = (cbuId: any) => 'v2/user/me/bank_account/' + cbuId

export const netCheckOutDiscount = (id: string) => '/checkout_process/' + id + '/discount'

export const netCheckOutDiscountDelete = (id: string) => '/checkout_process/' + id + '/discount'

export const netCheckRefererAvailability = (dni: string) => '/checkout_process/' + dni + '/referer'

export const netCheckOutConfirm = (id: string) => '/checkout_process/' + id + '/confirm'

export const netCheckOutStatus = (id: string) => '/checkout_process/' + id + '/status'

export const netCheckoutLink = () => '/checkout_notification'

export const netSendUbiPagosData = () => '/payment/notification/ubiipagos'

export const netCarfacilUserData = (categoryId: string, quoteId: string) => '/v2/' + categoryId + '/quote/mercantil/locations/' + quoteId; //CARFACIL

export const netCheckoutData = (id: any) => '/checkout_process/' + id

export const netLocationInfo = (location: any) => '/v3/locations/' + location

//HOME

export const getOffersNet = () => '/offers';

export const getInsuredUser = () => '/public/product_insured_user/products';

export const removeInsuredFromUser  = () => '/public/product_insured_user/disable'

export const netOfferProductById = () => '/offers'

//DETAILS POLICY
export const netPolicyInfo = (productId: string) => '/insurance_policy/'+ productId;

export const netDownloadPolicyMA = (id: any) => '/v2/policy/mercantil/' + id;

export const netDownloadPolicySC = (id: any) => '/v2/policy/sancristobal/' + id;

//SUGGESTS
export const getUninsuredItems = () => '/public/product_uninsured/all';

export const uploadUninsuredItem = () => '/v2/product_suggested/';

export const getAllCategoriesSuggested = () => '/public/product_category/suggested';

export const getAllSubCategories = (category) => '/public/product_category/all_sub_categories?categoryId=' + category + '&page=1&amount=1000';

export const createPersingUrl = () => '/create_presignedUrl';

//RECLAMOS

export const netGetClaimTypes = (id: number) => '/insurance_policy/' + id + '/claim_types';

export const netPostClaimProcess = () => '/claim_process';

export const netGetClaimProcessById = (id: string) => '/claim_process/' + id;

export const netPutAnswerQuestion = (id: string, incompleteStepId: number, incompleteFormId: number) => '/claim_process/' + id + '/step/' + incompleteStepId + '/form/' + incompleteFormId

export const netGetUserReclamos = () => '/public/report';

//FORM

export const netGenericForm = (data: any) => '/generic_forms/' + data