
import React, { useEffect } from 'react'
import './Inicio.css'
import { useNavigate } from 'react-router-dom'

const Inicio = (props: any) => {

  const navigate = useNavigate();

  useEffect(() => {
    if(process.env.REACT_APP_NO_LANDING) navigate('/home')
  }, [])

  return (
    <div className="inicio-container">
    </div >
  );
}

export default Inicio;