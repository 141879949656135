import React, { useEffect, useState } from 'react'
import './FormFieldList.css'

const FormFieldList = (props: any) => {

    const [searchText, setSearchText] = useState<string>('');
    const [valuesList, setValuesList] = useState<any>([]);
    const [originalList, setOriginalList] = useState<any>([]);

    useEffect(() => {
        if(props.data?.input?.values) {
            let res = [...props.data?.input?.values];
            res = res?.filter((item: any) => item?.label !== "---------------")
            setOriginalList(res)
            eliminateDuplicateLabelObjects()
        }
    }, [props.data?.input?.values])

    useEffect(() => {
        if(originalList?.length > 0) setValuesList(originalList)
    }, [originalList])

    const eliminateDuplicateLabelObjects = () => {
        const uniqueLabels = new Set();
        setOriginalList((prevDataArray) =>
          prevDataArray.filter((item) => {
            if (!uniqueLabels.has(item.label)) {
              uniqueLabels.add(item.label);
              return true;
            }
            return false;
          })
        );
    };

    const handleItemSelected = (a: any) => {
        const data = {
          "questionId": props.data.id,
          "value": a,
        }
        props.onUpdateAlt(data)
    }

    const handleFilterList = (text: string) => {
        setSearchText(text)
        let filteredArray = [...originalList]
        filteredArray = filteredArray?.filter((obj: any) => obj.label?.toLowerCase().includes(text?.toLowerCase()));
        setValuesList(filteredArray);
    }

    return (
        <div className="FormFieldList-container">
            {props.data?.input?.values?.length >= 8 && 
                <div className="FormFieldList-container-buscador">
                    <input
                        className="FormFieldList-container-buscador-input"
                        placeholder={props.data?.display?.placeholder}
                        value={searchText}
                        onChange={(e: any) => handleFilterList(e.target.value)}
                        id='buscador'
                    />
                    <span className="material-symbols-outlined" style={{ color: 'var(--primary500)', fontSize: 20 }}>search</span>
                </div>
            }
            {valuesList?.length > 0 &&
                <div className="FormFieldList-container-list">
                    {valuesList?.map((a: any, index: any) => (
                        <div 
                            id={a.label} 
                            key={index}
                            className="FormFieldList-container-list-item"
                            onClick={() => handleItemSelected(a.value)} 
                        >
                            <b className="FormFieldList-container-list-item-text regular medium">
                                {a.label?.toUpperCase()}
                            </b>
                        </div>
                    ))}
                </div>
            }
            {valuesList?.length === 0 &&
                <div className="FormFieldList-container-empty">
                    <span className="material-symbols-outlined" style={{ color: 'var(--gray200)', fontSize: 80 }}>sentiment_dissatisfied</span>
                    <p className="FormFieldList-container-empty-title">¡Lo sentimos mucho!</p>
                    <p className="FormFieldList-container-empty-text">No encontramos resultados que coincidan con tu búsqueda.<br/>Por favor, intentá nuevamente.</p>
                </div>
            }
        </div>
    );
}

export default FormFieldList;