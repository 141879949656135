
import React, { useEffect, useState } from 'react'
import './DemoStart.css'
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import DemoColorSelector from './DemoColorSelector/DemoColorSelector';
import DemoTipografiaSelector from './DemoTipografiaSelector/DemoTipografiaSelector';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';

const DemoStart = (props: any) => {

  const [nombreEmpresa, setNombreEmpresa] = useState<string>('');
  const [color, setColor] = useState<any>(null);
  const [tipografia, setTipografia] = useState<any>(null);
  const [demo] = useState<any>(process.env.REACT_APP_DEMO);
  const navigate = useNavigate();

  useEffect(() => {
    if (!demo) navigate('/inicio')
  }, [demo])

  const sendDataDemo = () => {
    updateCSSVariables(colorList?.filter((a: any) => a.id === color)[0])
    props.setGlobalFontFamily(tipografiaList?.filter((a: any) => a.id === tipografia)[0].tipo)
    navigate('/inicio')
  }

  const updateCSSVariables = (list: any) => {
    const root = document.documentElement;
    root.style.setProperty('--primary200', list['--primary200']);
    root.style.setProperty('--primary300', list['--primary300']);
    root.style.setProperty('--primary400', list['--primary400']);
    root.style.setProperty('--primary500', list['--primary500']);
    root.style.setProperty('--primary700', list['--primary700']);
    root.style.setProperty('--primary800', list['--primary800']);
  }

  const tipografiaList = [
    {
        "id": 1,
        "tipo": "Gotham"
    },
    {
        "id": 2,
        "tipo": "Lato"
    },
    {
        "id": 3,
        "tipo": "Roboto"
    }
  ]

  const colorList = [
    {
      "id": 1,
      "--primary200": "#C5CCE2",
      "--primary300": "#8293C6",
      "--primary400": "#596FB3",
      "--primary500": "#2F4BA0",
      "--primary700": "#18368E",
      "--primary800": "#182650"
    },
    {
      "id": 2,
      "--primary200": "#CCE2F1",
      "--primary300": "#89BADB",
      "--primary400": "#338DC8",
      "--primary500": "#0070BA",
      "--primary700": "#00548C",
      "--primary800": "#00385D"
    },
    {
      "id": 3,
      "--primary200": "#C9C1F3",
      "--primary300": "#AFA1EC",
      "--primary400": "#9482E6",
      "--primary500": "#7963E0",
      "--primary700": "#3D3270",
      "--primary800": "#1E1938"
    },
    {
      "id": 4,
      "--primary200": "#CAE1D8",
      "--primary300": "#95B1A6",
      "--primary400": "#519B7D",
      "--primary500": "#5C7F71",
      "--primary700": "#466A5C",
      "--primary800": "#32443D"
    }
  ]

  const isDisabled = () => {
    return (nombreEmpresa === '' || !color || !tipografia)
  }

  return (
    <div className="demoStart-container" style={{ display: demo ? 'flex' : 'none' }}>
      <div className="demoStart-container-input">
        <CustomTextInput
          label='Nombre de la empresa'
          required
          changeText={(e: string) => setNombreEmpresa(e)}
          value={nombreEmpresa}
        />
      </div>
      <DemoColorSelector
        colorList={colorList}
        colorSelected={color}
        setColor={setColor}
      />
      <DemoTipografiaSelector
        tipografiaList={tipografiaList}
        tipografiaSelected={tipografia}
        setTipografia={setTipografia}
      />
      <div className="demoStart-container-button">
        <MainButton
          id='EMPEZAR DEMO'
          fill
          color='var(--primary500)'
          text='EMPEZAR DEMO'
          onButtonPressed={() => sendDataDemo()}
          disabled={isDisabled()}
        />
      </div>
    </div >
  );
}

export default DemoStart;