import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    checkOutOfferHomeById,
    getPolicyInfo,
} from '../../actions';
import Header from "../../components/generalComponents/header/Header";
import './Circulacion.css'
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import Moment from 'moment/moment'

let isTabletOrMobile = false;
const Circulacion = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
    const { id }: any = useParams();
    const [productPackage, setProductPackage] = useState<any>(null);
    const [checkoutData, setCheckoutData] = useState<any>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [policy, setPolicy] = useState<any>(null);

    const handleUrlParams = () => {
        const checkoutId = searchParams.get('checkoutId');
        if(checkoutId) checkOutOfferHomeById(checkoutId, setCheckoutData)
    };

    useEffect(() => {
        if(checkoutData) {
            setProductPackage(checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0])
        }
    }, [checkoutData])

    useEffect(() => {
      getPolicyInfo(id, setPolicy)
      handleUrlParams()
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: animated smooth scroll
      });
    }, [])

    return (
        <div className="circulacion-container">
            <Header home colored special />
            <div className="circulacion-container-main">
              <h4 className="circulacion-container-main-title bold">Tarjeta de circulación</h4>
              <div className="circulacion-container-main-card">
                <div className="circulacion-container-main-card-top">
                  <div className="circulacion-container-main-card-top-imgWrapper">
                    <img src={policy?.productPolicyDTO?.insurerImage} className="circulacion-container-main-card-top-imgWrapper-img"/>
                  </div>
                </div>
                <div className="circulacion-container-main-card-main">
                  <p className="circulacion-container-main-card-main-product bold">{policy?.productPolicyDTO?.name}</p>
                  <div className="circulacion-container-main-card-main-section">
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Póliza</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{policy?.number}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Cliente</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{props.userData?.name + ' ' + props.userData?.lastName}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">DNI</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{props.userData?.documentNumber}</b>
                    </div>
                  </div>
                  <div className="circulacion-container-main-card-main-section">
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Cobertura</b>
                      <div className="circulacion-container-main-card-main-section-item-subItem">
                        <b className="circulacion-container-main-card-main-section-item-subItem-text regular large">{productPackage?.description}</b>
                      </div>
                    </div>
                  </div>
                  <div className="circulacion-container-main-card-main-section">
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Vigencia desde</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{Moment(policy?.fechaDesde).format('DD/MM/YYYY')}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Vigencia hasta</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{Moment(policy?.fechaHasta).format('DD/MM/YYYY')}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Medio de pago</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{checkoutData?.paymentInfo?.paymentType === 'realcash' ? 'Efectivo' : 'N/A'}</b>
                    </div>
                  </div>
                  <div className="circulacion-container-main-card-main-section" style={{ marginBottom: 0 }}>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Patente</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{checkoutData?.productInfo?.carplate[0]}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Motor</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{checkoutData?.productInfo?.carmotornumber[0]}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Chasis</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{checkoutData?.productInfo?.carchasisnumber[0]}</b>
                    </div>
                    <div className="circulacion-container-main-card-main-section-item">
                      <b className="circulacion-container-main-card-main-section-item-text regular large">Año</b>
                      <b className="circulacion-container-main-card-main-section-item-text regular large">{policy?.productPolicyDTO?.model}</b>
                    </div>
                  </div>
                </div>
              </div>
              <b className="circulacion-container-main-note regular large">El uso de esta credencial es exclusivo de su titular y debe<br/>presentarse con el documento de identidad.</b>
              <div className="circulacion-container-main-button">
                <MainButton
                  id='VOLVER' 
                  border
                  color='var(--primary500)'
                  text='VOLVER'
                  onButtonPressed={() => navigate(-1)}
                />
              </div>
            </div>
        </div>
    )
}

export default Circulacion;

