

import React from 'react'
import './GruaAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const GruaAlert = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const openWhatsApp = () => {
        const phoneNumber = '+5491128080012';
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappUrl, '_blank');
    };

    const callNumber = (number: any) => {
        if(isTabletOrMobile) window.open(`tel:${number}`);
    }

    return (
        <div className="gruaAlert-container">
            <div className="gruaAlert-container-close" onClick={() => props.close()}>
                <span className="material-symbols-outlined">close</span>
            </div>
            <div className="gruaAlert-container-data">
                <img className="gruaAlert-container-data-img" src={props.insurer?.img}/>
                <p className="gruaAlert-container-data-title">Pedir una grúa</p>
                <b className="gruaAlert-container-data-text regular large">Para solicitar una grúa contactate a través<br/>de los teléfonos:</b>
                <div className="gruaAlert-container-data-numbers">
                    <b onClick={() => callNumber('0800-777-2634')} className="gruaAlert-container-data-number regular large">0800-777-2634</b>
                    <b className="gruaAlert-container-data-text regular large">&nbsp;o&nbsp;</b>
                    <b onClick={() => callNumber('011-4335-5792')} className="gruaAlert-container-data-number regular large">011-4335-5792</b>
                </div>
                <b className="gruaAlert-container-data-text regular large">O comunicate al Whatsapp:</b>
                <b onClick={() => openWhatsApp()} className="gruaAlert-container-data-number regular large" style={{ cursor: 'pointer' }}>+5491128080012</b>
            </div>
            <div className="gruaAlert-container-botton">
                <MainButton
                    id='ACEPTAR'
                    fill
                    color='var(--primary500)'
                    text='ACEPTAR'
                    onButtonPressed={() => props.close()}
                />
            </div>
        </div>
    );
}

export default GruaAlert;