

import React from 'react'
import './AtencionAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const AtencionAlert = (props: any) => {
    const openWhatsApp = () => {
        const phoneNumber = '+5491169602118';
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappUrl, '_blank');
    };      

    const sendEmail = () => {
        const email = 'hola@wecover.co';
        const subject = '';
        const body = '';
    
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
        // Open the default email client
        window.location.href = mailtoLink;
    };

    return (
        <div className="atencionAlert-container">
            <div className="atencionAlert-container-close" onClick={() => props.close()}>
                <span className="material-symbols-outlined">close</span>
            </div>
            <div className="atencionAlert-container-data">
                <p className="atencionAlert-container-data-title bold">¿CÓMO PODEMOS AYUDARTE?</p>
                <div className="atencionAlert-container-data-section">
                    <p className="atencionAlert-container-data-section-text regular">Comunicate con nosotros a través<br/>de nuestro whatsapp:</p>
                    <p onClick={() => openWhatsApp()} className="atencionAlert-container-data-section-data regular">+54 9 11 6960-2118</p>
                </div>
                <div className="atencionAlert-container-data-section">
                    <p className="atencionAlert-container-data-section-text regular">o por mail a:</p>
                    <p onClick={() => sendEmail()} className="atencionAlert-container-data-section-data regular">hola@wecover.co</p>
                </div>
            </div>
            <div className="atencionAlert-container-botton">
                <MainButton
                    id='ACEPTAR'
                    fill
                    color='var(--primary500)'
                    text='ACEPTAR'
                    onButtonPressed={() => props.close()}
                />
            </div>
        </div>
    );
}

export default AtencionAlert;