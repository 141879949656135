import React from 'react'
import GoogleLogin from '../../../assets/images/svgs/googleLoign.svg';
import './MainButton.css'
import { ReactSVG } from 'react-svg'

const MainButton = (props: any) => {
 
  if (props.google) {
    return (
      <button 
        id={props.id}
        className="mainButton-containerGoogle"
        onClick={() => props.onButtonPressed()}
        disabled={props.disabled}
        style={{ opacity: props.disabled ? 0.4 : 1 }}
      >
        <ReactSVG src={GoogleLogin}/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.text}
      </button>
    )
  } else if (props.border) {
    return (
      <button 
        id={props.id}
        className="mainButton-containerBorder"
        onClick={() => props.onButtonPressed()}
        disabled={props.disabled}
        style={{ 
          opacity: props.disabled ? 0.4 : 1,
          border: `1px solid ${props.color}`,
          color: props.color
        }}
      >{props.text}</button>
    );
  } else if (props.fill) {
    return (
      <button 
        id={props.id}
        className="mainButton-containerFill"
        onClick={() => props.onButtonPressed()}
        disabled={props.disabled}
        style={{ 
          opacity: props.disabled ? 0.4 : 1,
          backgroundColor: props.color,
          border: `1px solid ${props.color}`,
          color: props.textColor ? props.textColor : 'var(--white)'
        }}
      >{props.text}</button>
    );
  } else if (props.empty) {
    return (
      <button 
        id={props.id}
        className="mainButton-containerEmpty"
        onClick={() => props.onButtonPressed()}
        disabled={props.disabled}
        style={{ 
          opacity: props.disabled ? 0.4 : 1,
          color: props.color
        }}
      >{props.text}</button>
    );
  } else {
    return (
      <button
        id={props.id}
        className="mainButton-container"
        onClick={() => props.onButtonPressed()}
        disabled={props.disabled}
        style={{ opacity: props.disabled ? 0.4 : 1 }}
      >{props.text}</button>
    );
  }
}

export default MainButton;