import React from "react";
import './PaymentCbu.css'

const PaymentCbu = (props: any) => {
    return (
        <div className="paymentCbu-container">
            <div className="paymentCbu-container-wrapper">
                <div className="paymentCbu-container-wrapper-card">
                    <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--gray700)' }}>account_balance</span>
                    <p className="paymentCbu-container-wrapper-card-text regular">CBU **********************</p>
                </div>
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--statusSuccessPrimary)' }}>done</span>
            </div>
        </div>
    )
}

export default PaymentCbu;

