import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import './MisCoberturas.css'
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../components/generalComponents/header/Header';
import {
  fetchDataForHome,
  removeInsuredArticle,
  offerProductById
} from '../../actions'
import Cookies from 'universal-cookie';
import HomeSeguro from '../../components/homeComponents/HomeSeguro/HomeSeguro';
import Modal from 'react-modal';
import DesactivarPolizaAlert from '../../components/alertComponents/DesactivarPolizaAlert/DesactivarPolizaAlert';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import {Helmet} from "react-helmet";
import Headers from '../../components/Headers/Headers';
import { netCheckOutOfferHomeById } from '../../net/Connector';
import axios from '../../actions/instanceActions'
import DesactivarPolizaSuccessAlert from '../../components/alertComponents/DesactivarPolizaSuccessAlert/DesactivarPolizaSuccessAlert';


let isTabletOrMobile = false;
const MisCoberturas = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
  
  const { hash }: any = useLocation();
  const navigate = useNavigate();

  const [originalParam, setOriginalParam] = useState<any>(null)
  const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
  const [pendienteList, setPendienteList] = useState<any>(null);
  const [vigenteList, setVigenteList] = useState<any>(null);
  const [noVigenteList, setNoVigenteList] = useState<any>(null);
  const [headerListSelected, setHeaderSelectedList] = useState<string>('')
  const [refresh, setRefresh] = useState<boolean>(false);
  const [desactivarAlertSuccess, setDesactivarAlertSuccess] = useState<boolean>(false);
  const [reDeactivate, setReDeactivate] = useState<boolean>(false);
  const [checkoutDataList, setCheckoutDataList] = useState<any>([]);
  const [headerList, setHeaderList] = useState<any>(null);
  const [appId] = useState<any>(process.env.REACT_APP_ID);

  useEffect(() => {
    handleUrlParams()
  }, [])

  const handleUrlParams = () => {
    let cookiesUA = new Cookies();
    cookiesUA = cookiesUA.get('userAccessToken')
    if(cookiesUA) {
      props.fetchDataForHome(setPendienteList, setVigenteList, setNoVigenteList)
    }
  }

  const desactivarModal = () => {
    return (
      <Modal
        isOpen={desactivarAlertId !== -1}
        onRequestClose={() => setDesactivarAlertId(-1)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <DesactivarPolizaAlert
          desactivarAlert={()=> setDesactivarAlertId(-1)}
          desactivarPressed={() => {
            props.removeInsuredArticle(desactivarAlertId, setRefresh, refresh, setDesactivarAlertSuccess)
            setDesactivarAlertId(-1)
          }}
          reDeactivate={reDeactivate}
        />
      </Modal>
    )
  }

  const desactivarSuccessModal = () => {
    return (
      <Modal
        isOpen={desactivarAlertSuccess}
        onRequestClose={() => setDesactivarAlertSuccess(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <DesactivarPolizaSuccessAlert
          dismiss={()=> setDesactivarAlertSuccess(false)}
        />
      </Modal>
    )
  }

  const getProductRegionId = (categoryId: any) => {
    switch (categoryId) {
      case 18:
        return 9242;
      case 19:
        return 9243;
      case 20:
        return 9244;
      default:
        return 9242;
    }
  }

  const headerItemPendiente = {
    name: 'Pendiente',
    click: () => {
        setHeaderSelectedList('Pendiente')
        navigate('#Pendiente')
    },
    list: pendienteList,
    emptyList: {
      icon: 'safety_check',
      text: 'Actualmente no tenés coberturas pendientes',
      boton: true
    }
  }

  const headerItemVigente = {
    name: 'Vigente',
    click: () => {
        setHeaderSelectedList('Vigente')
        navigate('#Vigente')
    },
    list: vigenteList,
    emptyList: {
      icon: 'verified_user',
      text: 'Actualmente no tenés coberturas vigentes',
      boton: true
    }
  }

  const headerItemNoVigente = {
    name: 'No vigente',
    click: () => {
        setHeaderSelectedList('No vigente')
        navigate('#No vigente')
    },
    list: noVigenteList,
    emptyList: {
      icon: 'gpp_bad',
      text: 'Actualmente no tenés coberturas no vigentes',
      boton: false
    }
  }

  useEffect(() => {
    let list: any = []
    if(pendienteList?.length !== 0 && pendienteList) list.push(headerItemPendiente)
    if(vigenteList?.length !== 0 && vigenteList) list.push(headerItemVigente)
    if(noVigenteList?.length !== 0 && noVigenteList) list.push(headerItemNoVigente)
    setHeaderList(list)
  }, [pendienteList, vigenteList, noVigenteList])

  useEffect(() => {
    if(headerList && headerList?.length !== 0) {
      setHeaderSelectedList(headerList[0]?.name)
      navigate('#' + headerList[0]?.name)
    }
  }, [headerList])

  return (
    <div className="misCoberturas-container">
      <Helmet>
        <title>Mi administrador de Pardo Salud</title>
        <meta name="description" content="Tu salud asegurada en segundos y de forma completamente virtual. Personalizá tu cobertura y gestioná todo online a través de nuestra página web." />
      </Helmet>
      <Header home colored special />
      <div className="misCoberturas-container-main">
        <h4 className="misCoberturas-container-main-title bold">Mis coberturas</h4>
        {headerList?.length !== 0 && <Headers
          list={headerList}
          selected={headerListSelected}
        />}
        {headerList?.filter((a: any) => a.name === headerListSelected)[0]?.list && 
          <div className="misCoberturas-container-main-list">
            {headerList?.filter((a: any) => a.name === headerListSelected)[0]?.list?.map((a: any, index: any) => (
              <HomeSeguro 
                key={index}
                data={a}
                goToDetails={() => navigate(GetCountryUrl(props.countryId) + `policy/${a.id}` + `?checkoutId=${a.checkout?.id}`)}
                desactivar={(id: number, reDeactivate: any) => {
                  setReDeactivate(reDeactivate)
                  setDesactivarAlertId(id)
                }}
                activarPoliza={(id: number) => appId == 6 ? {} : props.offerProductById(id, getProductRegionId(a.categoryId), originalParam, navigate, props.countryId)}
                reclamo={a.claimStatus}
                setCheckoutDataList={setCheckoutDataList}
                checkoutDataList={checkoutDataList}
              />
            ))}
          </div>
        }
        {headerList?.length === 0 && 
          <div className="misCoberturas-container-main-emptyList">
            <span className="material-symbols-outlined" style={{ fontSize: 64, color: 'var(--gray200)'}}>verified_user</span>
            <p className="misCoberturas-container-main-emptyList-text regular">Actualmente no tenés coberturas</p>
          </div>
        }
      </div>
      {desactivarModal()}
      {desactivarSuccessModal()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userProductsActive: state.home.userProductsActive,
  };
};


export default connect(mapStateToProps, {
  fetchDataForHome,
  removeInsuredArticle,
  offerProductById
})(MisCoberturas);