import Candado from '../../../assets/lotties/candado.json';
import Lottie from 'react-lottie';
import { useEffect } from 'react';
import Header from '../../../components/generalComponents/header/Header';
import { useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { setAccessTokenAux, userAccessTokenSet } from '../../../actions';
import './ChangePasswordSuccess.css'

const ChangePasswordSuccess = (props: any) => {

  const dispatch = useDispatch();

  const navigate = useNavigate()

  useEffect(() => {
    const timer1= setTimeout(() => {
      props.userAccessTokenSet(null);
      dispatch(setAccessTokenAux(null));
      dispatch({ type: 'USER_DATA', payload: null });
      navigate('/login');
    }, 3800);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className="changePasswordSuccess-container">
      <Header home/>
      <div className="changePasswordSuccess-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Candado,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={200}
          width={200}
          isStopped={false}
          isPaused={false}
        />
        <h2 className="changePasswordSuccess-container-main-title light">¡Listo!</h2>
        <h6 className="changePasswordSuccess-container-main-text regular">Tu contraseña se cambió correctamente.</h6>
        <h6 className="changePasswordSuccess-container-main-text regular">Te redirigiremos al inicio de sesión</h6>
        <h6 className="changePasswordSuccess-container-main-text regular">para ingresar con tu nueva contraseña.</h6>
      </div>
    </div>
  )
}

export default connect(null, { userAccessTokenSet })(ChangePasswordSuccess);
