import React, {useState, useRef, useEffect} from 'react'
import { ReactComponent as ExitQuote } from '../../../../../assets/images/svgs/exitQuote.svg';
import { ReactComponent as LupaQuote } from '../../../../../assets/images/svgs/lupaQuote.svg'; 
import './FormFieldSelectorSearcher.css'

const FormFieldSelectorSearcher = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)
  const [completeList, setCompleteList] = useState<any>(props?.data?.input?.values)
  const [textSearch, setTextSearch] = useState<any>('')
  const [preSelectedItemIndex, setPreSelectedItemIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const mainRef: any = useRef()

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    const data = {
        "questionId": props?.data?.id,
        "value": a.value,
        "label": a.label + ' ' + a.subTitle
    }
    setSelectedItem(data.label)
    props.onUpdateAlt(data)
    setPreSelectedItemIndex(-1)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          setOpenList(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);


  const filterByText = (e: String) => {
    setTextSearch(e)
    let auxShow = props?.data?.input?.values?.filter((a: any) => (a.name ? a.name : a.label + ' ' + a.subTitle)?.toLowerCase()?.includes(e.toLowerCase()))
    setCompleteList(auxShow)
  }

  return (
    <div ref={mainRef} className="FormFieldSelectorSearcher-container" >
      <div 
        id={'Selector ' + props?.data?.display?.name}
        className="FormFieldSelectorSearcher-container-selector" 
        onClick={() => {
          if(!selectedItem) {
            setOpenList(true)
            filterByText('')
          }
        }}
        style={{
          borderBottomLeftRadius: openList ? 0 : 25,
          backgroundColor: selectedItem ? 'var(--accent500)' : 'var(--gray100)'
        }}
      >
        {selectedItem ? 
          <p className="FormFieldSelectorSearcher-container-selector-value" style={{color: 'var(--white)'}}>{selectedItem}</p>
          :  
          <input 
            id={`select_${props.data?.id}`}
            value={textSearch}
            className="FormFieldSelectorSearcher-container-selector-input" 
            placeholder={props.data?.display?.placeholder}
            onChange={(e) => filterByText(e.target.value)}
            onKeyDown={(e: any) => {
              if(e.keyCode === 40 && completeList.length - 1 !== preSelectedItemIndex) {
                setPreSelectedItemIndex(preSelectedItemIndex + 1)
              } else if (e.keyCode === 38 && preSelectedItemIndex !== 0) {
                setPreSelectedItemIndex(preSelectedItemIndex - 1)
              } else if (e.keyCode === 13) {
                handleItemSelected(completeList[preSelectedItemIndex])
              }
            }}
            onFocus={(event) => {
              setOpenList(true)
              event.target.setAttribute('autocomplete', 'off');
              filterByText('')
            }}
            onBlur={() => setOpenList(false)}
          />
        }
        {selectedItem ? 
          <div 
            id='Delete item'
            className="FormFieldSelectorSearcher-container-selector-lupa"
            style={{borderBottomRightRadius: openList ? 0 : 25}}
            onClick={() => {
              setOpenList(true)
              setSelectedItem(null)
              props.onUpdateAlt({})
              filterByText('')
            }}
          >
            <ExitQuote/>
          </div>
          :
          <div 
            className="FormFieldSelectorSearcher-container-selector-lupa"
            style={{borderBottomRightRadius: openList ? 0 : 25}}
          >
            <LupaQuote height={21} width={21}/>
          </div>
        }
      </div>
      <div 
        className="FormFieldSelectorSearcher-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById('Selector ' + props.data?.display?.name)?.offsetWidth
        }}
      >
        {completeList?.map((a: any, index: any) => (
          <p
            id={a.label + ' ' + a.subTitle + ' item'}
            key={index}
            className="FormFieldSelectorSearcher-container-list-listItemText"
            onMouseOver={() => setPreSelectedItemIndex(index)}
            onClick={() => handleItemSelected(a)}
            style={{
              backgroundColor: preSelectedItemIndex === index ? 'var(--accent500)' : 'transparent',
              color: preSelectedItemIndex === index ? 'var(--white)' : 'var(--primary500)'
            }}
          >{a.name ? a.name : a.subTitle ? a.label + ' ' + a.subTitle : a.label}</p>
        ))}
      </div>
    </div>
  );
} 

export default FormFieldSelectorSearcher;