import React, {useState} from 'react'
import CustomTextInput from '../../../../customTextInput/CustomTextInput';
import './FormFieldNumberStyleOne.css'
import MainButton from '../../../../MainButton/MainButton';

const FormFieldNumberStyleOne = (props: any) => {

    const [localText, setLocalText] = useState<string>('')

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter' && localText?.length >= 4) {
            confirm()
        }
    }

    const confirm = () => {
        const data = {
            "questionId": props.data?.id,
            "value": parseInt(localText),
        }
        props.next(data, props.data?.input?.config?.button === 'COTIZAR')
    }

    return (
        <div className="formFieldNumberStyleOne-container" style={{ width: props.parent === 'Cotizar' ? '33%' : '100%' }}>
            <CustomTextInput
                maxLength={props.data?.input?.config?.max}
                label={props.data?.display?.discripcion}
                required={props.data?.required}
                placeholder={props.data?.display?.placeholder}
                changeText={(e: string) => {
                    const res = e.replace(/\D/g,'')
                    setLocalText(res)
                    props.onUpdate(res)
                }}
                value={localText}
                onKeyDown={(event: any) => handleKeyPress(event)}
            />
            {props.parent === 'Cotizar' && 
                <div className="formFieldNumberStyleOne-container-button">
                    <MainButton
                        id={props.data?.input?.config?.button === 'COTIZAR' ? 'COTIZAR' : 'CONTINUAR'}
                        fill
                        color='var(--primary500)'
                        text={props.data?.input?.config?.button === 'COTIZAR' ? 'COTIZAR' : 'CONTINUAR'}
                        disabled={localText?.length < 4}
                        onButtonPressed={() => confirm()}
                    />
                </div>
            }
        </div>
    );
}

export default FormFieldNumberStyleOne;