import React, { useEffect, useState } from 'react'
import Header from '../../components/generalComponents/header/Header';
import { useNavigate, useSearchParams } from 'react-router-dom'
import './CotizarOpen.css'
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
import {Helmet} from "react-helmet";
import automovilLottie from '../../assets/lotties/automovil.json'
import Lottie from 'react-lottie';
import Modal from 'react-modal';
import NumeroCotizacionModal from '../../components/alertComponents/NumeroCotizacionModal/NumeroCotizacionModal';
import FormFieldNumberStyleTwo from '../../components/generalComponents/GlobalForms/FormFields/FormFieldNumber/FormFieldNumberStyleTwo/FormFieldNumberStyleTwo';
import { answerOpenQuestion } from '../../actions';

let isTabletOrMobile = false;
const CotizarOpen = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  
  //ERROR ALERT
  const [numeroCotizacionError, setNumeroCotizacionError] = useState<boolean>(false);
  const [numeroCotizacionErrorText, setNumeroCotizacionErrorText] = useState<string>('');
  
  //OTHER
  const navigate = useNavigate()
  const [admin, setAdmin] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);

  useEffect(() => {
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('CanGoBack', appBaseIframe)
    } else {
      window.parent.postMessage('CanGoBack', 'http://localhost:3000')
    }
    handleUrlParams()
  }, [])

  const handleUrlParams = () => {
    let admin: any = searchParams.get("admin")
    if (admin) {
      setAdmin(true)
    }
  }

  const numeroCotizacionModal = () => {
    return (
      <Modal
        isOpen={numeroCotizacionError}
        onRequestClose={() => setNumeroCotizacionError(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '25%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.50)'
          }
        }}
      >
        <NumeroCotizacionModal
          close={() => setNumeroCotizacionError(false)}
          text={numeroCotizacionErrorText}
        />
      </Modal>
    )
  }

  return (
    <div className="cotizarOpen-container" >
      <Helmet>
        <html lang={useIntl().formatMessage({ id: 'meta.cotizar.html' })}/>
        <title>{useIntl().formatMessage({ id: 'meta.cotizar.title'}, { name: 'Automovil'} )}</title>
        <meta name="description" content={useIntl().formatMessage({ id: 'meta.cotizar.description' })} />
        <meta property='keywords' content={useIntl().formatMessage({ id: 'meta.cotizar.keywords' })} />
      </Helmet>
      {!admin && <Header colored />}
      {loadingOffer ? 
        <div className="cotizarOpen-container-mainLoading">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: automovilLottie
            }}
            height={isTabletOrMobile ? 300 : 400}
            width={isTabletOrMobile ? 300 : 400}
          />
          <h4 className="cotizarOpen-container-mainLoading-text regular">Yendo a buscar tu cotización</h4>
        </div>
        :
        <div className="cotizarOpen-container-main">
          <FormFieldNumberStyleTwo 
            onUpdateAlt={(input: number) => answerOpenQuestion(input, 12, setLoadingOffer, navigate, admin, setNumeroCotizacionError, setNumeroCotizacionErrorText)}
          />
        </div>
      }
      {numeroCotizacionModal()}
    </div>
  );
}

export default CotizarOpen;