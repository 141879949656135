// lib
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Modal from 'react-modal';
import { connect } from "react-redux";
import {
    removeInsuredArticle,
    getRestrictions,
    offerProductById,
    checkOutOfferHomeById,
    getPolicyInfo,
    actionDownloadPolicyMA,
    actionDownloadPolicySC
} from '../../actions';
import Header from "../../components/generalComponents/header/Header";
import './DetailsPolicy.css'
import AccesosRapidos from "../../components/homeComponents/AccesosRapidos/AccesosRapidos";
import AtencionAlert from "../../components/alertComponents/AtencionAlert/AtencionAlert";
import DesactivarPolizaAlert from "../../components/alertComponents/DesactivarPolizaAlert/DesactivarPolizaAlert";
import SiniestroAlert from "../../components/alertComponents/SiniestroAlert/SiniestroAlert";
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import VerMasModal from "../../components/modalComponents/VerMasModal/VerMasModal";
import { estadoList } from "../../util/EstadoListColors";
import DesactivarPolizaSuccessAlert from "../../components/alertComponents/DesactivarPolizaSuccessAlert/DesactivarPolizaSuccessAlert";
import Moment from 'moment/moment'
import AsistenciaAlert from "../../components/alertComponents/AsistenciaAlert/AsistenciaAlert";
import GruaAlert from "../../components/alertComponents/GruaAlert/GruaAlert";
import PaymentEfectivo from "./PaymentEfectivo/PaymentEfectivo";
import PaymentCbu from "./PaymentCbu/PaymentCbu";
import PaymentCard from "./PaymentCard/PaymentCard";

let isTabletOrMobile = false;
const DetailsPolicy = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
    const { id }: any = useParams();
    const [productPackage, setProductPackage] = useState<any>(null);
    const [activeSwitch, setActiveSwitch] = useState<boolean>(false);
    const [siniestroAlert, setSiniestroAlert] = useState<boolean>(false);
    const [gruaAlert, setGruaAlert] = useState<boolean>(false);
    const [asistenciaAlert, setAsistenciaAlert] = useState<boolean>(false);
    const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
    const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
    const [verMasAlert, setVerMasAlert] = useState<boolean>(false);
    const [verMasInfo, setVerMasInfo] = useState<any>(null);
    const [checkoutData, setCheckoutData] = useState<any>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [policy, setPolicy] = useState<any>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [desactivarAlertSuccess, setDesactivarAlertSuccess] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);

    const handleUrlParams = () => {
        const checkoutId = searchParams.get('checkoutId');
        if(checkoutId) checkOutOfferHomeById(checkoutId, setCheckoutData)
    };

    useEffect(() => {
        if(checkoutData) {
            setProductPackage(checkoutData?.offer?.packages?.filter((a) => a?.id === checkoutData?.offer?.currentPackage)[0])
        }
    }, [checkoutData])

    useEffect(() => {
        if(policy) {
          setActiveSwitch(policy?.enable)
        }
    }, [policy])

    useEffect(() => {
      getPolicyInfo(id, setPolicy)
      handleUrlParams()
    }, [refresh])

    useEffect(() => {
      getPolicyInfo(id, setPolicy)
      handleUrlParams()
    }, []);

    const asistenciaModal = () => {
      return (
        <Modal
          isOpen={asistenciaAlert}
          onRequestClose={() => setAsistenciaAlert(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : '30%',
              padding: isTabletOrMobile ? '10px' : '20px',
            },
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)'
            }
          }}
        >
          <AsistenciaAlert
            insurer={{ img: policy?.productPolicyDTO?.insurerImage, name: policy?.productPolicyDTO?.insurerName }}
            close={() => setAsistenciaAlert(false)}
          />
        </Modal>
      )
    }

    const gruaModal = () => {
      return (
        <Modal
          isOpen={gruaAlert}
          onRequestClose={() => setGruaAlert(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : '30%',
              padding: isTabletOrMobile ? '10px' : '20px',
            },
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)'
            }
          }}
        >
          <GruaAlert
            insurer={{ img: policy?.productPolicyDTO?.insurerImage, name: policy?.productPolicyDTO?.insurerName }}
            close={() => setGruaAlert(false)}
          />
        </Modal>
      )
    }

    const siniestroModal = () => {
        return (
          <Modal
            isOpen={siniestroAlert}
            onRequestClose={() => setSiniestroAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <SiniestroAlert
              insurer={{ img: policy?.productPolicyDTO?.insurerImage, name: policy?.productPolicyDTO?.insurerName }}
              close={() => setSiniestroAlert(false)}
            />
          </Modal>
        )
      }

      const verMasModal = () => {
        return (
          <Modal
            isOpen={verMasAlert}
            onRequestClose={() => setVerMasAlert(false)}
            style={{
              content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '25px',
                  width: isTabletOrMobile ? '80%' : '50%',
                  padding: '20px'
              },
              overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }
            }}
          >
            <VerMasModal
              info={verMasInfo}
              close={() => setVerMasAlert(false)}
            />
          </Modal>
        )
      }
    
      const desactivarModal = () => {
        return (
          <Modal
            isOpen={desactivarAlertId !== -1}
            onRequestClose={() => setDesactivarAlertId(-1)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <DesactivarPolizaAlert
              desactivarAlert={()=> setDesactivarAlertId(-1)}
              desactivarPressed={() => {
                props.removeInsuredArticle(desactivarAlertId, setRefresh, refresh, setDesactivarAlertSuccess)
                setDesactivarAlertId(-1)
              }}
              reDeactivate={policy?.enable && estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.status === 'CANCEL_REQUESTED'}
            />
          </Modal>
        )
      }

      const desactivarSuccessModal = () => {
        return (
          <Modal
            isOpen={desactivarAlertSuccess}
            onRequestClose={() => setDesactivarAlertSuccess(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <DesactivarPolizaSuccessAlert
              dismiss={()=> setDesactivarAlertSuccess(false)}
            />
          </Modal>
        )
      }
    
      const atencionModal = () => {
        return (
          <Modal
            isOpen={atencionAlert}
            onRequestClose={() => setAtencionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <AtencionAlert
              close={() => setAtencionAlert(false)}
            />
          </Modal>
        )
    }


    const paymentStyle: any = {
      'realcash': { component: PaymentEfectivo },
      'simple_creditcard': { component: PaymentCard },
      'cbu': { component: PaymentCbu },
      undefined: { component: PaymentEfectivo }
    }

    const PaymentComponent: any = paymentStyle[checkoutData?.paymentInfo?.paymentType]?.component;

    return (
        <div className="detailsPolicy-container">
            <Header home colored special />
            <div className="detailsPolicy-container-main">
                <div className="detailsPolicy-container-main-left">
                    <h4 className="detailsPolicy-container-main-left-title bold">Detalle de cobertura</h4>
                    <div className="detailsPolicy-container-main-left-product">
                        <div className="detailsPolicy-container-main-left-product-imgWrap">
                            <img className="detailsPolicy-container-main-left-product-imgWrap-img" src={checkoutData?.offer?.product?.image} />
                        </div>
                        <div className="detailsPolicy-container-main-left-product-texts">
                          <p className="detailsPolicy-container-main-left-product-texts-text bold">{checkoutData?.offer?.product?.marca + ' - ' + checkoutData?.offer?.product?.modelo}</p>
                          <b className="detailsPolicy-container-main-left-product-texts-text regular medium" style={{ color: 'var(--gray500)'}}>{checkoutData?.offer?.product?.title}</b>
                        </div>
                        <div 
                            className="detailsPolicy-container-main-left-product-switch"
                            onClick={() => {
                              if(policy?.enable) setDesactivarAlertId(id)
                            }}
                            style={{ backgroundColor: activeSwitch ? 'var(--primary500)' : 'var(--gray200)'}}
                        >
                            <div 
                                className="detailsPolicy-container-main-left-product-switch-ball"
                                style={{ transform: activeSwitch ? 'translate(25px, 0px)' : 'translate(5px, 0px)' }}
                            ></div>
                        </div>
                    </div>
                    <div className="detailsPolicy-container-main-left-poliza">
                        <b className="bold large" style={{ color: 'var(--gray700)' }}>PÓLIZA N° {policy?.number}</b>
                        <div className="detailsPolicy-container-main-left-poliza-number">
                            <b className="bold large" style={{ color: 'var(--gray700)' }}>Estado:&nbsp;</b>
                            <b className="bold large" style={{ color: estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color }}>{estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}</b>
                        </div>
                    </div>
                    <div className="detailsPolicy-container-main-left-descargarPoliza">
                      <MainButton
                        id='DESCARGAR PÓLIZA' 
                        empty
                        color='var(--primary500)'
                        disabled={!policy?.number || !policy?.enable || policy?.number === 0}
                        text={downloading ? 'DESCARGANDO...' : 'DESCARGAR PÓLIZA'}
                        onButtonPressed={() => {
                          if(productPackage?.insurer?.id === 17) {
                            actionDownloadPolicyMA(policy?.number, setDownloading)
                          } else {
                            actionDownloadPolicySC(policy?.number, setDownloading)
                          }
                        }}
                      />
                    </div>
                    <h6 className="detailsPolicy-container-main-left-subTitle bold">Tarjeta de circulación</h6>
                    <div className="detailsPolicy-container-main-left-circulacion" style={{ opacity: policy?.enable ? 1 : 0.6}}>
                      <div className="detailsPolicy-container-main-left-circulacion-top">
                        <div className="detailsPolicy-container-main-left-circulacion-top-imgWrapper">
                          <img src={policy?.productPolicyDTO?.insurerImage} className="detailsPolicy-container-main-left-circulacion-top-imgWrapper-img"/>
                        </div>
                      </div>
                      <div className="detailsPolicy-container-main-left-circulacion-bottom">
                        <div className="detailsPolicy-container-main-left-circulacion-bottom-wrapper">
                          <p className="detailsPolicy-container-main-left-circulacion-bottom-wrapper-name bold">{policy?.productPolicyDTO?.name}</p>
                          <b className="detailsPolicy-container-main-left-circulacion-bottom-wrapper-product regular large">{policy?.productPolicyDTO?.model + ' | ' + checkoutData?.productInfo?.carplate[0]}</b>
                          <b className="detailsPolicy-container-main-left-circulacion-bottom-wrapper-date regular large">Vence: {Moment(policy?.fechaHasta).format('DD/MM/YYYY')}</b>
                          <div className="detailsPolicy-container-main-left-circulacion-bottom-wrapper-button">
                            <MainButton
                              id='ABRIR'
                              empty
                              color='var(--white)'
                              text='ABRIR'
                              disabled={!policy?.enable}
                              onButtonPressed={() => navigate('/circulacion/' + id + '?checkoutId=' + checkoutData?.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="detailsPolicy-container-main-left-subTitle bold">Accesos rápidos</h6>
                    <div className="detailsPolicy-container-main-left-links">
                      <AccesosRapidos
                        text='Pedir una grúa'
                        icon='auto_towing'
                        disable={!policy?.enable}
                        onPressed={() => setGruaAlert(true)}
                      />
                      <AccesosRapidos
                        text='Solicitar asistencia'
                        icon='build'
                        disable={!policy?.enable}
                        onPressed={() => setAsistenciaAlert(true)}
                      />
                      <AccesosRapidos
                        text='Denunciar un siniestro'
                        icon='e911_emergency'
                        disable={checkoutData?.status !== 'FINISHED'}
                        onPressed={() => setSiniestroAlert(true)}
                      />
                      <AccesosRapidos
                        text='Atención al cliente'
                        icon='contact_support'
                        onPressed={() => setAtencionAlert(true)}
                      />
                    </div>
                </div>
                <div className="detailsPolicy-container-main-right">
                    <div className="detailsPolicy-container-main-right-title">
                        <h6 className="bold" style={{ color: 'var(--gray700)' }}>¿Cuál es tu cobertura?</h6>
                    </div>
                    <div className="detailsPolicy-container-main-right-list">
                      <div className="detailsPolicy-container-main-right-list-item">
                        <h6 className="detailsPolicy-container-main-right-list-item-title regular">Cobertura</h6>
                        <h6 className="detailsPolicy-container-main-right-list-item-value regular">{isTabletOrMobile ? productPackage?.name : productPackage?.description}</h6>
                      </div>
                      <div className="detailsPolicy-container-main-right-list-item">
                        <h6 className="detailsPolicy-container-main-right-list-item-title regular">Plan de pago</h6>
                        <h6 className="detailsPolicy-container-main-right-list-item-value regular">Mensual</h6>
                      </div>
                      <div className="detailsPolicy-container-main-right-list-item">
                        <h6 className="detailsPolicy-container-main-right-list-item-title regular">Vigencia</h6>
                        <h6 className="detailsPolicy-container-main-right-list-item-value regular">Anual</h6>
                      </div>
                    </div>
                    <div className="detailsPolicy-container-main-right-pay">
                      <h6 className="detailsPolicy-container-main-right-pay-title bold">Medio de pago</h6>
                      <PaymentComponent/>
                    </div>
                </div>
            </div>
            {atencionModal()}
            {siniestroModal()}
            {asistenciaModal()}
            {gruaModal()}
            {desactivarModal()}
            {verMasModal()}
            {desactivarSuccessModal()}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        countryId: state.general.countryId,
        userData: state.user.userData
    };
};

export default connect(mapStateToProps, {
    removeInsuredArticle,
    getRestrictions,
    offerProductById
})(DetailsPolicy);

