import {
    FETCH_CATEGORIAS,
    CATEGORIA_SELECTED
} from '../actions/types';

const INITIAL_STATE = {
    categorias: null,
    categoriaSelected: -1
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_CATEGORIAS:
            return { ...state, categorias: action.payload };
        case CATEGORIA_SELECTED:
            return { ...state, categoriaSelected: action.payload };
        default:
            return state;
    }
};