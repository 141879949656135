import React from 'react'
import './OfferPlan.css'
import MainButton from '../../../components/generalComponents/MainButton/MainButton'
import Moment from 'moment/moment'

const OfferPlan = (props: any) => {

  return (
    <div 
      className="offerPlan-container"
      style={{ 
        border: props.favorite ? '3px solid var(--primary200)' : '3px solid var(--gray50)'
      }}
    >
      <div 
        className="offerPlan-container-title"
        style={{
          backgroundColor: props.favorite ? 'var(--primary200)' : 'var(--gray100)'
        }}
      >
        <b className="offerPlan-container-title-text regular medium">{props.title}</b>
      </div>
      <div className="offerPlan-container-price">
        <h4 className="offerPlan-container-price-price bold">{props.data?.price?.formattedValue}</h4>
        <b className="offerPlan-container-price-per bold medium">{props.per}</b>
      </div>
      <div className="offerPlan-container-period">
        <b className="offerPlan-container-period-text regular small">VIGENCIA ANUAL</b>
        <b className="offerPlan-container-period-text regular small">DESDE {Moment(new Date()).format('DD/MM/YYYY')} HASTA {Moment(new Date()).add(1, 'years').format('DD/MM/YYYY')}</b>
      </div>
      <div className="offerPlan-container-line"/>
      <div className="offerPlan-container-details">
        <b className="offerPlan-container-details-text regular small">SUMA ASEGURADA {props.data?.coverageGroups[0]?.coverages?.filter((a: any) => a.title?.toUpperCase() == 'SUMA ASEGURADA')[0]?.formattedValue}</b>
        <b className="offerPlan-container-details-text regular small">PRIMA {props.data?.coverageGroups[0]?.coverages?.filter((a: any) => a.title?.toUpperCase() == 'PRIMA')[0]?.formattedValue}</b>
      </div>
      <div className="offerPlan-container-boton">
        <MainButton
          id='CONTRATAR'
          fill
          color='var(--primary500)'
          text='CONTRATAR'
          onButtonPressed={() => props.contratar()}
        />
      </div>
    </div>
  )
}

export default OfferPlan;